import React, { useState } from 'react'
import { MonthFilter } from '../../../components/MonthFilter'
import { NextsToExpire } from '../../../components/NextsToExpire'
import { PanelFilter } from '../../../components/PanelFilter'
import { YearFilter } from '../../../components/YearFilter'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'
import { ClaimsByPanelGraphic } from './ClaimsByPanelGraphic'
import { ClaimsByStateGraphic } from './ClaimsByStateGraphic'
import { ClaimsByMonthGraphic } from './ClaimsByMonthGraphic'
import StockByPanelGraphic from './StockByPanelGraphic'


const General = () => {

  const [ panel, setPanel ] = useState<string>( '' )
  const [ month, setMonth] = useState<string>( '' )
  const [ year,   setYear ] = useState<string>( '' )

  return (
    <LoggedTemplate
      pageTitle="Geral"
      pageDescription="Gráficos gerais."
      filters={ <PanelFilter onChange={ panel => setPanel( panel ) } /> }
    >
      <div className="row">
        <div className="col s12 no-padding">
          <div className="col s3">
            <YearFilter onChange={ year => setYear( year ) } />
          </div>
          <div className="col s3">
            <MonthFilter onChange={ month => setMonth( month ) }/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m4">
          <ClaimsByStateGraphic filters={ { panel, year, month } } />
        </div>
        <div className="col s12 m6">
          <NextsToExpire panel={ panel } limitDays={ 30 }/>
        </div>
      </div>

      <div className="row">
        <StockByPanelGraphic filters={ { panel } } />
      </div>

      <div className="row">
        <ClaimsByPanelGraphic filters={ { panel, year, month } } />
      </div>

      <div className="row">
        <ClaimsByMonthGraphic year={ year }/>
      </div>

    </LoggedTemplate>
  )
}

export { General }
