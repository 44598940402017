import styled from "styled-components"

const Preloader = ( { size }:Props ) => {

  return (
    <Container>
      <div className={`preloader-wrapper ${size} active `}>
        <div className="spinner-layer spinner-green-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div><div className="gap-patch">
            <div className="circle"></div>
          </div><div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

interface Props {
  size?: "big" | "small"
}

export { Preloader }
