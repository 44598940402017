import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import api from '../api'
import { User } from '../store/ducks/user/types'
import { Panel } from '../types/Panel'
import { Button } from './Button'
import { Preloader } from './PreLoader'

const AdminUserForm = ( { userData, panels, onSuccess, ...props }:Props ) => {

  const formRef = useRef<HTMLFormElement>( null )
  const [ data , setData ] = useState<any>({
    confirmPassword: '',
    password: '',
    name: '',
    email: '',
    panels: []
  })
  const [ isLoading, setIsloading ] = useState<boolean>( false )
  const panelsArray = useMemo<Panel[]>( () => panels ?? [], [ panels ] )

  const submit = useCallback( async () => {
    if( !formRef.current ) return
    setIsloading( true )

    const formData = new FormData( formRef.current )

    const data = Object.fromEntries( formData.entries() )

    if( userData?.id ){
      await api.put( 'admin-users/' + userData.id, data ).then( res => {
        onSuccess && onSuccess( res.data )
      }).catch( console.log )
      }else{

      await api.post( 'admin-users', data ).then( res => {
        onSuccess && onSuccess( res.data )
      }).catch( console.log )

    }
    setIsloading( false )
  }, [ formRef, userData, onSuccess ] )

  useEffect( () => {
    setData( userData )
  }, [ userData ] )

  return (
    <form className="row" ref={formRef} >
      <div className="input-field col s12 m6 offset-m3">
        <label className="active">Nome</label>
        <input
          type='text'
          name="name"
          value={ data?.name }
          onChange={ e => setData( (data:any) => ({ ...data, name: e.target.value } ) )}
        />
      </div>
      <div className="input-field col s12 m6 offset-m3">
        <label className="active">Email</label>
        <input
          type='email'
          name="email"
          value={ data?.email }
          onChange={ e => setData( (data:any) => ({ ...data, email: e.target.value } ) )}
        />
      </div>
      <div className="input-field col s12 m6 offset-m3">
        <label className="active">Paineis</label>
        { panelsArray?.map( panel => (
          <div className="col" key={ uniqueId() }  style={{ marginTop: 10 }} >
            <label>
              <input
                type='checkbox'
                name={ `panels[${panel.id}]` }
                onChange={ e => {
                  setData( ( data:any ) => ( {
                    ...data,
                    panels: [
                      ...panelsArray?.filter( ( p:Panel ) => {
                        if( p.id !== panel.id ) {
                          return data.panels?.find( (pChild:any) => pChild.id === p.id )
                        }
                        if( e.target.checked ) return true

                        return false
                      } )
                    ]
                  }) )
                } }
                checked={ data?.panels?.find( ( p:Panel ) => p.id === panel.id ) !== undefined }
              />
              <span> { panel?.name } </span>
            </label>
          </div>
        ) )}
      </div>
      <div className="input-field col s12 m6 offset-m3">
        <label className="active">Senha</label>
        <input
          type='password'
          name="password"
          value={ data?.password }
          onChange={ e => setData( (data:any) => ({ ...data, password: e.target.value } ) )}
        />
      </div>
      <div className="input-field col s12 m6 offset-m3">
        <label className="active">Confirmação de senha</label>
        <input
          type='password'
          name="confirmPassword"
          value={ data?.confirmPassword }
          onChange={ e => setData( (data:any) => ({ ...data, confirmPassword: e.target.value } ) )}
        />
      </div>
      <div className="col s12 center">
        {
          isLoading ? <Preloader />
          :
          <Button
            action={ submit }
          >
            Salvar
          </Button>
        }
      </div>
    </form>
  )
}

interface Props{
  onSuccess?: ( data:any ) => void,
  onSubmit?: ( data:FormData ) => void,
  userData?: User,
  panels?: Panel[]
}

export { AdminUserForm }
