import { User } from "./types"
import { LOCAL_STORAGE_KEYS } from "../../../utils/constants"

export const loadInitialState = ():User | undefined => {
  try {
    const json = localStorage.getItem(LOCAL_STORAGE_KEYS.USER)

    if( !json ) return undefined
    const data = JSON.parse( json )

    return data
  } catch (error) {
    return undefined
  }
}
