import { uniqueId } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { Pagination } from '../../hooks/usePagination'
import { Preloader } from '../PreLoader'

const InfiniteScrollTable = ( { headers, renderData, pagination, height, ...props }:Props ) => {

  const next = useCallback( () => {
    if( !pagination ) return
    if ( !pagination.loading ) return pagination.load()
  }, [ pagination ] )

  const firstLoad = useMemo<boolean>( () => pagination.firstLoadDone, [ pagination ])
  const wrapperId = useMemo( ():string => uniqueId().toString(), [] )

  return !pagination ? null : (
    <Wrapper
      id={ wrapperId }
      style={{
        height: height ?? 500
      }}
    >
      <InfiniteScroll
        next={ next }
        dataLength={ pagination.list.length }
        hasMore={ !pagination.end }
        loader={ <div className='center'><Preloader /></div>  }
        scrollableTarget={ wrapperId }
        style={{
          overflow: 'unset'
        }}
      >
        <Table className={ props.striped ? 'striped' : ' '}>
          <thead>
            <tr>
              { headers.map( header => (
                <HeaderCell key={ uniqueId() }>
                  { header }
                </HeaderCell>
                )
              ) }
            </tr>
          </thead>
          <tbody>
            { firstLoad && pagination.list.map( item => (
              <Line
                hoverable={ props.onClickItem !== undefined }
                key={ uniqueId() }
                onClick={ () => props.onClickItem && props.onClickItem( item ) }
              >
                { renderData?.map( func => React.cloneElement( func( item ), {
                  key: uniqueId()
                } ) ) }
              </Line>
            ) )}

            { !pagination.end ? null :
              <tr>
                { pagination.list.length > 0 ?
                  <td colSpan={ headers.length } className="center">
                    {
                      props.endMessage ??
                      'Fim dos resultados'
                    }
                    </td>
                  :
                  <td colSpan={ headers.length } className="center">
                    {
                      props.emptyMessage ??
                      'Sem items para estes filtros'
                    }
                  </td>
              }
              </tr>
            }
          </tbody>
        </Table>
      </InfiniteScroll>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: auto;
`

const HeaderCell = styled.th`
  position: sticky;
  top: 0;
  background-color: white;
  border: 1px solid #E1E1E1;
  border-top: 0;

  &:after{
    background-color: white;
    position: absolute;
    top: 0;
    height: 100%;
    left: -1px;
    width: calc( 100% + 2px);
    z-index: -1;
    content: '';
  }
`
const Line = styled.tr<{ hoverable?: boolean }>`

  cursor: ${ props => !props.hoverable ? 'auto' :
    `pointer`
  };
`

const Table = styled.table`
  tbody{
    tr{
      &:nth-child( odd ){
        background-color: #F6FAF5; //
      }
    }
  }
`

interface Props {
  striped?:boolean
  headers: string[],
  pagination: Pagination<any>,
  renderData?: ( ( data:any ) => JSX.Element )[],
  onClickItem?: ( item:any ) => void,
  endMessage?: string,
  emptyMessage?: string,
  height?: number
}

export { InfiniteScrollTable }
