import React, { useEffect } from 'react'
import { Header } from './Header'
import styled from 'styled-components'
import { LogoutButton } from '../components/LogoutButton'
import useIsLogged from '../hooks/useIsLogged'
import { useHistory } from 'react-router-dom'

const LoggedTemplate = ( { children, ...props }:Props ) => {

  const history = useHistory()
  const isLogged = useIsLogged()

  useEffect( () => {
    if( !isLogged ) history.push( '/login' )
  }, [ isLogged, history ] )

  useEffect( () => {
    const page =  props.pageTitle
    document.title = ( page ? page + ' - ' : '' ) + 'Kantar'
  }, [ props ] )

  return (
    <>
      <Header { ...props } />
      <LogoutButton />
      <Main>
        { children }
      </Main>
    </>
  )
}

const Main = styled.main`
  padding-right: 20px;
  padding-bottom: 40px;
  @media( min-width: 992px ){
    padding-left: 330px;
  }
`

interface Props extends React.PropsWithChildren<any>{
  pageTitle?: string,
  pageDescription?: string
  filters?: React.ReactNode
}

export { LoggedTemplate }
