import { encode } from 'querystring'
import React, { memo, useCallback, useEffect, useState } from 'react'
import api from '../../../api'
import { ColumnGraphic, ColumnGraphicData } from '../../../components/ColumnGraphic'

const StockByPanelGraphic = ( { filters }:Props ) => {

  const [ stockByPanel, setStockByPanel ] = useState<{
    labels?: any[],
    maxValue: number
    data?: ColumnGraphicData[]
  }>()

  const loadStockByPanel = useCallback( async () => {
    await api.get( '/stock/by-panel?' + encode( filters ) ).then( res => {

      let data:ColumnGraphicData[] = [];
      const labels:{ label:string, color: string }[] = res.data?.panels.map( (panel:any) => (
        {
          label: panel?.name ?? '',
          color: panel?.colorInGraphic ?? "#" + ( ( Math.random() * 16777 ) + 0x10000).toString(16).substr(-4)
        }
      )) ?? []

      res.data.partners.forEach( ( partner:any ) => {
        partner.cards.forEach( ( card:any ) => {
          let bars:any[] = []
          labels.forEach( label => {
            const key = label.label as keyof typeof partner.cards
            bars.push({
              amount: card[key],
              color: label.color,
            })
          })
          data.push({
            bars,
            label: card.name
          })
        } )
      } )

      setStockByPanel( {
        labels,
        data,
        maxValue: res.data.maxAmount ?? 0
      } )

    }).catch( console.log )
  }, [ filters ] )


  useEffect( () => {
    loadStockByPanel()
  }, [ loadStockByPanel ] )

  return(

    <ColumnGraphic
      title="Estoque por painel"
      subTitle="Gift Cards em estoque."
      labels={ stockByPanel?.labels }
      data={ stockByPanel?.data }
      maxValue={ stockByPanel?.maxValue ?? 0 }
    />

  )
}

interface Props{
  filters?: {
    panel?: string,
  }
}
export default memo( StockByPanelGraphic )
