import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'
import { Modal } from '../../../components/Modal'
import styled from 'styled-components'
import api from '../../../api'
import { PartnerType } from '../../../types/PartnerType'
import { NewPatnerForm } from '../../../components/NewPatnerForm'
import { PartnerCard } from '../../../components/PartnerCard'
import { NewPartner } from '../../../components/NewPartner'
import { ConfirmModal } from '../../../components/ConfirmModal'
import { ContextMenu } from '../../../components/ContextMenu'
import M from 'materialize-css'

const Partners = () => {

  const [ isModalOpen, setIsModalOpen ] = useState<boolean>( false )

  const [ partners, setPartners ] = useState<PartnerType[]>([])

  const deletePartner = useCallback ( async ( partner:PartnerType ) => {
    await api.delete( `gift-cards/partners/${ partner.id }`)
      .then( res => {
        setPartners( partners => partners.filter( ( partner ) => partner.id !== res.data.id ) )
        M.toast({
          html: '<strong>Parceiro excluido com sucesso</strong>',
          classes: 'green rounded white-text'
        })
      } )
      .catch( console.log )
  }, [ ] )

  const loadPartners = useCallback( async () => {
    await api.get( 'gift-cards/partners' ).then( res => {
      setPartners( res.data )
    }).catch( console.log )
  }, [] )

  useEffect( () => {
    loadPartners()
  }, [ loadPartners ] )


  return (
    <LoggedTemplate pageTitle="Bandeiras">
      <div className="row">
        <div className="col">
          <Modal
            isOpen={ isModalOpen }
            onOpen={ () => setIsModalOpen( true ) }
            trigger={ NewPartner }
            footer={ () =>
              <a href="#!" className="left modal-close btn-flat">Cancelar</a>
            }
          >
            <ModalContent>
              <NewPatnerForm onSuccess={ ( partner ) => {
                setPartners( partners => [ partner, ...partners ] )
                setIsModalOpen( false )
              } }/>
            </ModalContent>
          </Modal>
        </div>
        { partners.map( partner => (
          <div  key={ uniqueId() } className="col">
            <ContextMenu
              trigger={ <PartnerCard  partner={ partner } disableLink={ true } /> }
              options={[
                {
                  text: <ConfirmModal
                    onConfirm={ () => deletePartner( partner ) }
                    trigger={
                      <span style={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                      }}>
                        Deletar
                      </span>
                    }
                  />
                }
              ]}
            />
          </div>
        ) ) }
      </div>
    </LoggedTemplate>
  )
}

const ModalContent = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export { Partners }
