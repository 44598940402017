import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../utils/constants'

const NewAdminUserCard = ( { ...props } ) => {

  return (
    <Container { ...props } >
      <Icon className="material-icons">
        add_circle_outline
      </Icon>
    </Container>
  )
}

const Icon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
`

const Container = styled.div`
  padding: 16px;
  background: 'white';
  min-width: 260px;
  height: 120px;
  border-radius: 8px;
  color: 'black';
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 1px solid #D8D8D8;
  transition: all 300ms;
  cursor: 'pointer';

  &:hover{
    box-shadow: 0 0 20px -5px ${COLORS.APP_GREEN};
    transition: all 300ms;
  }

`

export { NewAdminUserCard }
