import store from "../store"
import { useCallback } from "react"

const useGetUser = () => {

  const loadState = useCallback( () => {
    const state = store.getState()
    return state.User?.data
  }, [ ] )

  return loadState()
}

export default useGetUser
