import React from 'react'
import styled from 'styled-components'
import { User } from '../store/ducks/user/types'
import { COLORS } from '../utils/constants'

const AdminUserCard = ( { user, ...props }:Props ) => {

  return (
    <Container { ...props } >
      <Icon className="material-icons">
        person
      </Icon>
      <Info >
        <InfoItem> { user?.name } </InfoItem>
        <InfoItem> { user?.email } </InfoItem>
        { !user?.is_admin ? null :
          <div className="valign-wrapper">
            Admin <i className="material-icons">stars</i>
          </div>
        }
      </Info>
    </Container>
  )
}

const Icon = styled.i`
  border-radius: 50%;
  border: 1px solid #ccc;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  align-self: flex-start;
`

const Info = styled.div`
  margin-left: 15px;
  height: 100%;
`

const InfoItem = styled.div`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
`

const Container = styled.div`
  padding: 16px;
  background: 'white';
  min-width: 260px;
  height: 120px;
  border-radius: 8px;
  color: 'black';
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid #D8D8D8;
  transition: all 300ms;
  cursor: 'pointer';

  &:hover{
    box-shadow: 0 0 20px -5px ${COLORS.APP_GREEN};
    transition: all 300ms;
  }

`

interface Props {
  user?: User
}

export { AdminUserCard }
