import { encode } from 'querystring'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../api'
import { BarGraphic, ColumnGraphicData } from '../../../components/BarGraphic'


const ClaimsByStateGraphic = ( { filters }:Props ) => {

  const [ claimsByState, setClaimsByState ] = useState<{
    total?: number,
    maxValue: number
    data?: ColumnGraphicData[]
  }>()

  const loadClaimsByState = useCallback( async () => {
    await api.get( '/claims/by-state?' + encode( filters ) ).then( res => {
      const data:ColumnGraphicData[] = res.data.states.map( ( state:any ) => {
        return {
          bars: [
            {
              amount: state.claims ?? 0,
              fontColor: 'black'
            }
          ],
          label: state.uf
        }
      } )
      setClaimsByState( {
        total: res.data.total,
        data,
        maxValue: res.data.max ?? 0
      } )

    }).catch( console.log )
  }, [ filters ] )

  useEffect( ()=> {
    loadClaimsByState()
  }, [ loadClaimsByState ])

  return (

    <BarGraphic
      title="Resgater por UF"
      subTitle="Resgates por Região"
      total={ claimsByState?.total }
      maxValue={ claimsByState?.maxValue ?? 0 }
      data={ claimsByState?.data }
    />

  )
}

interface Props{
  filters: {
    year?: string,
    month?: string,
    panel?: string,
  }
}
export  { ClaimsByStateGraphic }
