import React, { useState } from 'react'
import { UserClaimList } from '../../../components/ClaimsList/UserClaimList'
import { PanelFilter } from '../../../components/PanelFilter'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'


const UserClaims = () => {

  const [ filters, setFilters ] = useState<any>({})

  return (
    <LoggedTemplate
      pageTitle="Resgates"
      pageDescription="Resgates efetuados pelos usuários."
      filters={ <PanelFilter onChange={ panel => {
        setFilters( (filters:any) => ({ ...filters, panel: panel }) )
      } }/> }
    >
      <UserClaimList filters={ filters } />
    </LoggedTemplate>
  )
}


export { UserClaims }
