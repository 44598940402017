import { Panel } from "../../../types/Panel";

/**
 * Action types
 */
export enum UserTypes {
  SET_USER = '@user/SET_USER'
}

/**
 * Data types
 */
export interface User {
  id: number,
  email: string,
  access_token: string,
  name: string,
  is_admin: boolean,
  panels?: Panel[]
}

/**
 * State type
 */
export interface UserState {
  readonly data?: User | null
}
