import React, { useCallback, useEffect, useRef } from 'react'
import M from 'materialize-css'
import { modalOptions } from '../utils/modalOptions'

const Modal = ( {
  onOpen,
  onClose,
  isOpen,
  options,
  children,
  trigger: Trigger,
  footer: Footer,
}:Props ) => {

  const modal = useRef<any>( null )

  const open = useCallback( () => {
    const instance = M.Modal.getInstance( modal.current )

    if( !instance ) return console.log( 'modal not initialized')

    instance.open()

    return onOpen && onOpen()

  }, [ modal, onOpen ] )

  useEffect( () => {

    const instance = M.Modal.getInstance( modal.current )

    if( !instance )
      M.Modal.init( modal.current, {
        ...modalOptions,
        ...options,
        dismissible: true,
        onCloseStart: onClose,
      } )

    return () => instance && instance.destroy()
  }, [ modal, options, onClose ] )

  useEffect( () => {

    const instance = M.Modal.getInstance( modal.current )

    if( instance ){
      if( isOpen ) instance.open()
      else instance.close()
    }

  }, [ isOpen, modal ] )

  return (
    <>
      {
        Trigger && React.cloneElement( <Trigger />, {
          onClick: open
        } )
      }
      <div className="modal  modal-fixed-footer" ref={ modal }>
        <div className="modal-content">
          { children }
        </div>
      { Footer &&
        <div className="modal-footer">
          <Footer />
        </div>
      }
      </div>
    </>
  )
}


interface Props extends React.PropsWithChildren<any> {
  trigger?: React.FunctionComponent<any>
  footer?: React.FunctionComponent<any>
  options?: M.ModalOptions,
  isOpen?: boolean,
  onOpen?: () => void,
  onClose?: () => void,
}

export { Modal }
