export const COLORS = {
  APP_GREEN: '#6AD600'
}


export enum LOCAL_STORAGE_KEYS {
  USER = '@ibope-kantar-gift-card/user'
}


export const MONTHS = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro'
]
