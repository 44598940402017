import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import { PartnerType } from '../types/PartnerType'
import { Preloader } from './PreLoader'

const StockByPartnerTable = ( { panel }:Props ) => {


  const [ partners, setPartners ] = useState<PartnerType[]>( [] )
  const [ loading, setLoading ] = useState< boolean >( false )

  const loadData = useCallback( async () => {
    setLoading( true )

    await api.get( '/stock/all-partners?&panel=' + panel ?? '' ).then( res => {
      setPartners( res.data )
    }).catch( console.log )

    setLoading( false )

  }, [ panel ] )

  useEffect( () => {
    loadData()
  }, [ loadData ] )

  return (
    loading ?
    <div className="row">
      <div className="col s12 center">
        <Preloader size="big"/>
      </div>
    </div>
    : false ? null :
    <Container>
      <Table>
        <Thead>
          <tr>
            <th>Fornecedor</th>
            <th>Valor</th>
            <th>Custo pts</th>
            <th>Quantidade</th>
            <th>Expiram em 30 dias</th>
            <th>Total Fornecedor</th>
          </tr>
        </Thead>
        <tbody>
          { partners.map( ( partner ) => (
            partner.cards?.map( ( card, index, self ) => (
              <>
                <tr key={ uniqueId() }>
                  <td className="center">{ partner.name }</td>
                  <td className="center">R${ card.giftValue }</td>
                  <td className="center">{ card.pointsValue }</td>
                  <td className="center">{ card.avaliableCodesCount }</td>
                  <td className="center red-text">{ card.expireIn30Days }</td>
                  { index > 0 ? null :
                    <td rowSpan={ self.length } className="center" style={{ fontWeight: 'bold', backgroundColor: 'white'}}>
                      { partner.avaliableCodesCount }
                    </td>
                  }
                </tr>
                { index < ( self.length - 1) || partner === partners[ partners.length - 1] ? null :
                  <tr className="divider">
                    <td colSpan={ 6 }></td>
                  </tr>
                }
              </>
            ) )
          ) )}
        </tbody>
      </Table>

    </Container>
  )
}

const Thead = styled.thead`
  height: 40px;
  width: 100%;
  color: #262626;
  font-size: 18px;
  font-weight: bold;
  th{
    text-align: center;
    white-space: nowrap;
    overflow-x: auto;
    position: sticky;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 0;
  }
`

const Table = styled.table`
  border: 1px solid #DFDFDF;
  width: 100%;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;

  tbody{
    tr{
      width: 100%;
      height: 40px;
      &:nth-child( odd ){
        background-color: #F6FAF5;
      }
      &.divider{
        height: 1px;
        background-color: black;
        td{
          padding: 0;
        }
      }

      td:first-child{
        font-weight: bold;
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #262626;
  position: relative;
  height: 500;
`

interface Props {
  panel?: string
}


export { StockByPartnerTable }
