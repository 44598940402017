import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { COLORS } from "../utils/constants"


const AutoSearchBar = ( { ...props }:Props ) => {

  const cooldownTime = useMemo( () => 1000, [] )
  const inputRef = useRef<HTMLInputElement>( null )
  const [ writing, setWriting ] = useState<any>( false )
  const [ inputValue, setInputValue ] = useState<string>( '' )

  const handleChange = useCallback( ( searchText:string ) => {

      setInputValue( searchText )
      if( writing ) setWriting( ( state:any ) => clearTimeout( state ) )

      setWriting(
        setTimeout(
            () => props.onChange && props.onChange( searchText ),
            cooldownTime
        )
      )

  }, [ props, writing, cooldownTime ] )


  return(
    <Container >
      <Input
        type="text"
        ref={inputRef}
        className="browser-default"
        value={inputValue}
        onChange={ ( e ) => handleChange( e.currentTarget.value.toString() ) }
      />
    </Container>
  )

}

const Input = styled.input`
  border: 1px solid #D7D8DB;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  height: 45px;
  padding-left: 10px;

  :focus{
    outline: none;
    box-shadow: 0 0 10px -3px ${ COLORS.APP_GREEN };
  }

`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface Props {
  onChange?: ( s:string ) => void
}


export { AutoSearchBar }
