import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../utils/constants'
import { Sidenav } from './Sidenav'

const Header = ( { children, pageTitle, pageDescription, filters }:Props ) => {

  return (
    <Container>
      <Sidenav />
        <PageInfo className="row" >
          <div className="col s12 m4">
            <PageTitle>
              { pageTitle }
            </PageTitle>
            <PageDescription>
              { pageDescription }
            </PageDescription>
          </div>
          { filters &&
            <div className="col s12 m8 ">
              { filters }
            </div>
          }
        </PageInfo>
      { children }
    </Container>
  )
}

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${ COLORS.APP_GREEN };
  display: flex;
  align-items: center;

  @media( min-width: 992px ){
    position: unset;
    padding-left: 330px;
    padding-top: 50px;
    background-color: transparent;
  }
`

const PageInfo = styled.div`
  width: 100%;
`

const PageTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #262626;
`

const PageDescription = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 28px;
  color: #101010;
`

interface Props extends React.PropsWithChildren<any>{
  pageTitle?: string,
  pageDescription?: string,
  filters?:  React.ReactNode
}

export { Header }
