import useGetUser from "./useGetUser"

const useIsLogged = ():boolean => {

  const user = useGetUser()

  return user !== null && user !== undefined
}

export default useIsLogged
