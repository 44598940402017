import React, { useState } from 'react'
import styled from 'styled-components'
import { ClaimsByPartner } from '../../../components/ClaimsByPartner'
import { MostClaimed } from '../../../components/MostClaimed'
import { PanelFilter } from '../../../components/PanelFilter'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'

const Tendencies = () => {

  const [ panel, setPanel ] = useState<string | undefined >()

  return (
    <LoggedTemplate
      pageTitle="Tendências"
      pageDescription="Resgates efetuados pelos usuários."
      filters={ <PanelFilter onChange={ panel => setPanel( panel ) }/> }
    >
      <div className="row">
        <div className="col s12 m8">
          <MostClaimed panel={ panel }/>
        </div>
        <div className="col s12 m4">
          <ClaimsByPartner panel={ panel }/>
        </div>
      </div>
    </LoggedTemplate>
  )
}

export { Tendencies }
