import React, { useEffect } from 'react'
import { Route, RouteProps, useHistory } from 'react-router-dom'
import useIsLogged from '../hooks/useIsLogged'

const PrivateRoute = ( { children, redirect, ...rest }:Props ) => {

    const logged = useIsLogged()
    const history = useHistory()

    useEffect( () => {
      !logged && history.push( redirect )
    }, [ logged, history, redirect ] )

    return (
      <Route
          { ...rest }
          render={ () => logged && children }
      />
    )
}

interface Props extends RouteProps, React.PropsWithChildren<any>{
  redirect: string,
}

export default PrivateRoute
