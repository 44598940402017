import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import { GiftCardType } from '../types/GiftCardType'
import { Preloader } from './PreLoader'

const MostClaimed = (  { panel }:Props ) => {

  const [ data, setData ] = useState<GiftCardType[]>([])
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ didMount, setDidMount ] = useState<boolean>( false )

  const loadClaims = useCallback( async ( ) => {

    if( !didMount ) return null

    const filter = !panel ? '' : `panel=${panel}`
    setData( [] )
    setLoading( true )
    await api.get( `gift-cards/tendencies/most-claimed?${filter}`  ).then( res => {
      return setData( res.data )
    }).catch( console.log )
    setLoading( false )
  }, [ panel, didMount ] )

  useEffect( () => {
    loadClaims()
  }, [ loadClaims ])

  useEffect( () => {
    setDidMount( true )
    return () => setDidMount( false )
  }, [] )

  return (
    <Container>
      Mais Resgatados
      <Itens>
        <Headers>
          <div>Valor</div>
          <div>Nome do cartão</div>
          <div>Fornecedor</div>
          <div>Resgates / Quantidade</div>
        </Headers>
        { loading && <Preloader /> }
        { data.map( ( card ) => {
            return (
              <Iten key={ uniqueId() } >
                <div> R${ card.giftValue } / { card.pointsValue } </div>
                <div>{ card.name }</div>
                <div>{ card.partner?.name }</div>
                <div>{ card.claimsCount } / { card?.codesCount } </div>
              </Iten>
            )
          }
        )}
      </Itens>
    </Container>
  )
}

const Headers = styled.div `
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  color: #262626;
  font-weight: bold;
  div{
    text-align: center;
    width: 25%;
    overflow-x: auto;
  }
`

const Itens = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DFDFDF;
  flex-direction: column;
  width: 100%;
`
const Iten = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;

  &:nth-child(even){
    background-color: #F6FAF5; //
  }

  div{
    text-align: center;
    width: 25%;
    white-space: nowrap;
    overflow-x: auto;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #262626;
  font-weight: bold;
`

interface Props{
  panel?: string
}

export { MostClaimed }

