import React, { useCallback, useEffect, useState } from 'react'
import { MONTHS } from '../utils/constants'
import { Select } from './Select'

const MonthFilter = ( { onChange: onSelect }:Props ) => {

  const [ itens, setItens ] = useState<MonthFilterType[]>( [] )

  const loadMonths = useCallback( ( ) => {
    const date = new Date()
    date.setMonth( 0 )

    let itens:MonthFilterType[] = []
    const loop = () => {

      for( let i = 0; i <= 11; i++ ){
        const label:string = `${ MONTHS[date.getMonth()] }`

        const obj:MonthFilterType = {
          label,
          month: (date.getMonth() + 1).toString()
        }
        date.setMonth( date.getMonth() + 1 )
        itens.push( obj )
      }
    }

    loop()

    setItens( itens )
  }, [ ] )

  useEffect( ( ) => {
    loadMonths()
  }, [ loadMonths ] )

  return (
    <Select
      onChange={ option => {
          onSelect && onSelect( option?.value ?? '' )
      }}
      options={[
        {
          label: 'Todos',
          value: null
        },
        ...itens.map( item => (
            {
              label: item.label.toString().toUpperCase(),
              value: item.month ?? ''
            }
          )
        )
      ] }
    />
   )
}

interface Props{
  onChange?: ( month:string ) => void
}

export interface MonthFilterType{
  label: string
  month: string
}

export { MonthFilter }
