import { useEffect, useRef, useMemo, useCallback } from 'react'
import M from 'materialize-css'
import { uniqueId } from 'lodash'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS } from '../utils/constants'
import useGetUser from '../hooks/useGetUser'

const Sidenav =  ( { isOpen = false }:Props ) => {

  const sidenav = useRef< any >( null )
  const { path } = useRouteMatch()
  const user = useGetUser()

  const menuItems = useMemo<{to: string, label:string, onlyAdmin?: boolean }[]>( () => ([
    {
      to: '/',
      label: 'Geral'
    },
    {
      to: '/estoque',
      label: 'Estoque'
    },
    {
      to: '/resgates',
      label: 'Resgates'
    },
    {
      to: '/tendencias',
      label: 'Tendências'
    },
    {
      to: '/gift-cards',
      label: 'Gift Cards'
    },
    {
      to: '/bandeiras',
      label: 'Bandeiras'
    },
    {
      to: '/expirados',
      label: 'Côdigos Expirados'
    },
    {
      to: '/usuarios-admin',
      label: 'Usuários Admin',
      onlyAdmin: true
    }
  ]), [] )

  // const toggle = useCallback( () => {

  //   const sidenavIntance = M.Sidenav.getInstance( sidenav.current )

  //   if( sidenavIntance ){
  //     if( !sidenavIntance.isOpen ) return sidenavIntance.open()

  //     return sidenavIntance.close()
  //   }

  // }, [ sidenav, isOpen ] )

  const isMenuItemActive = useCallback( ( to:string ):boolean => {
    if( path === to ) return true
    return path.includes( `${to}` ) && to !== '/'
  }, [ path ] )

  useEffect( () => {

    if( !M.Sidenav.getInstance( sidenav.current ) )
      M.Sidenav.init( sidenav.current )

  }, [ sidenav ] )


  return (
    <>
      <UlSidenav ref={ sidenav } className="sidenav sidenav-fixed">
        <SidenavHeader>
          <AppName>
            Kantar
          </AppName>
          Dashboard
        </SidenavHeader>
        {
          menuItems.filter( item => {
            if( !item.onlyAdmin ) return true

            return user?.is_admin
          }).map( item =>
            <MenuItemLi key={ uniqueId() } active={ isMenuItemActive( item.to ) }>
              <Link to={ item.to } > { item.label } </Link>
            </MenuItemLi>
          )
        }
        <AppVersion>
          Kantar <br />
          v1.0.0
        </AppVersion>
      </UlSidenav>
    </>
  )
}


const UlSidenav = styled.ul`
  background-color: #F6FAF5;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  li{
    transition: all 300ms;
  }
  li:hover{
    transition: all 300ms;
    background-color: ${ COLORS.APP_GREEN };
  }
`
const SidenavHeader = styled.div`
  min-height: 160px;
  max-height: 200px;
  height: 30vh;
  color: #262626;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;
`
const AppName = styled.div`
  color: ${ COLORS.APP_GREEN };
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
`

const AppVersion = styled.div`
  padding-left: 32px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  color: #262626;
  line-height: 24px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 20px;
`

interface MenuItemProps  {
  active?: boolean
}
const MenuItemLi = styled.li<MenuItemProps>`

  background-color: ${ props => props.active ? COLORS.APP_GREEN : 'transparent' };

  a{
    color: #101010 !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 40px !important;
    font-weight: bold !important;
  }

`

interface Props  {
  isOpen?: boolean
}
export { Sidenav }
