import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../utils/constants'


interface Position {
  top: number,
  left: number | 'unset'
  right: number | 'unset'
}

const ContextMenu = ( { options, trigger, open }:Props ) => {

  const [ isOpen, setIsOpen ] = useState<boolean>( false )
  const [ position, setPosition ] = useState<Position>({
    top: 0,
    left: 0,
    right: 'unset'
  })

  const containerRef = useRef<any>( null )

  useEffect( () => {

    open && setIsOpen( true  )
    !open && setIsOpen( false )

  }, [ open ] )

  const handleClick = useCallback( ( event:MouseEvent ) => {

    if (
      containerRef
      && containerRef.current
      && !containerRef.current.contains( event.target ) )
    {
      setIsOpen( false )
    }



  }, [ containerRef ] )

  useEffect( () => {

    document.addEventListener( 'mousedown', handleClick )

    return () => document.removeEventListener( 'mousedown', handleClick )

  }, [ handleClick ] )

  return (
    <>
      { trigger && React.cloneElement( trigger, {
          onClick: ( event:MouseEvent ) => {
            const isLeft:boolean = event.clientX < window.outerWidth / 2
            setPosition( {
              right: isLeft ? 'unset' : window.innerWidth - event.clientX - 20,
              left: !isLeft ? 'unset' : event.clientX,
              top: event.clientY
            } )
            setIsOpen( true )
          }
        }
      ) }
      <Container
        ref={ containerRef }
        style={{
          maxWidth: isOpen ? 'max-content' : '0px',
          maxHeight: isOpen ? '300px' : '0px',
          ...position
        }}
      >
        <Options>
          {
            options && options.map( option => (
              <Option
                key={ uniqueId() }
                onClick={ () => {
                    option.onClick && option.onClick()
                  }
                }
              >
                { option?.text }
              </Option>
            ))
          }
        </Options>

      </Container>
    </>
  )
}

const Options = styled.ul`
  margin: 0;
`

const Option = styled.li`
  padding: 10px;
  transition: all 100ms;
  cursor: pointer;
  &:hover{
    transition: all 100ms;
    background-color: ${ COLORS.APP_GREEN };
  }
`

const Container = styled.div`
  width: 100%;
  min-width: 150px;
  height: fit-content;
  position: fixed;
  overflow: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px -5px black;
  z-index: 999;
  transition: max-width 200ms, max-height 200ms;
`

interface Props {
  trigger?: JSX.Element
  open?: boolean
  options?: {
    onClick?: () => void,
    text?: JSX.Element
  }[]
}

export { ContextMenu }
