import React from 'react'
import styled, { CSSProperties }  from 'styled-components'
import { COLORS } from '../utils/constants'

const Button = ( {  children, action, style, color }:Props ) => {

  return (
    <Container onClick={ ( ) => {
      action && action(  )
    } } style={ style } className="" >
      { children }
    </Container>
  )
}

const Container = styled.div`
  background-color: ${ COLORS.APP_GREEN };
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -2px black;
  color: white;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
`

interface Props extends React.PropsWithChildren<any>{
  action?: ( ...params:any  ) => void,
  style?: CSSProperties,
  onClick?: ( ...params:any ) => void
}

export { Button }
