import { createStore, Store } from 'redux';
import { UserState } from './ducks/user/types';

import rootReducer from './ducks/rootReducer';

export interface ApplicationState {
  User?: UserState
}

const store: Store<ApplicationState> = createStore( rootReducer );

export default store;
