import React, { useCallback, useEffect, useRef, useState } from 'react'
import M from 'materialize-css'
import { modalOptions } from '../utils/modalOptions'
import styled from 'styled-components'

const ConfirmModal = ( {
  trigger,
  onCancel,
  onConfirm,
  children: Children,
}:Props ) => {

  const modal = useRef<any>( null )
  const confirm = useCallback( () => {
    const instance = M.Modal.getInstance( modal.current )

    if( !instance )
      return

    onConfirm && onConfirm()

    instance.close()

    }, [ modal, onConfirm ] )

  const cancel = useCallback( () => {

    const instance = M.Modal.getInstance( modal.current )

    if( !instance )
      return

    onCancel && onCancel()

    instance.close()

  }, [ modal, onCancel ] )

  const open = useCallback( () => {
    const instance = M.Modal.getInstance( modal.current )
    if( !instance )
      return

    instance.open()

  }, [ modal ] )

  useEffect( () => {

    const instance = M.Modal.getInstance( modal.current )

    if( !instance )
      M.Modal.init( modal.current, {
        ...modalOptions,
        dismissible: false
      } )

  }, [ modal ] )

  return (
    <>
      { trigger && React.cloneElement( trigger, {
        onClick: open
      }) }
      <div className="modal  modal-fixed-footer" ref={ modal } style={{
        maxWidth: 500
      }}>
        <div className="modal-content">
          {
            Children ??
              <DefaultTextContainer className="valign-wrapper" style={{
                height: '100%'
              }}>
                <DefaultText>Tem certeza que deseja realizar esta ação?</DefaultText>
              </DefaultTextContainer>
          }
        </div>
        <div className="modal-footer">
          <a href="#!" className="btn-flat left" onClick={ cancel }>
            Cancelar
          </a>

          <a href="#!" className="btn-flat right" onClick={ confirm }>
            Confimar
          </a>
        </div>
      </div>
    </>
  )
}


const DefaultTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const DefaultText = styled.div`
  flex: 1;
  text-align: center;
  font-size: 32px;
  font-weight: bold;


`

interface Props extends React.PropsWithChildren<any> {
  trigger?: JSX.Element
  onConfirm?: () => void,
  onCancel?: () => void,
}

export { ConfirmModal }
