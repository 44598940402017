import { useCallback } from "react"
import store from "../store"
import { User } from "../store/ducks/user/types"
import { setUserState } from "../store/ducks/user/actions"

const useSetUser = () => {

  const dispatch = store.dispatch

  const setUser = useCallback( ( data?:User ) => dispatch( setUserState( data ) ),
  [dispatch] )

  return setUser
}

export default useSetUser
