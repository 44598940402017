import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import { ErrorResponseType } from '../types/ErrroResponseType'
import { PartnerType } from '../types/PartnerType'
import { Button } from './Button'
import { Select } from './Select'
import M from 'materialize-css'
import { GiftCardType } from '../types/GiftCardType'
import { Preloader } from './PreLoader'


const NewGiftCardForm = ( { onError, onSuccess, giftcard, ...props }:Props ) => {

  const [ partners, setPartners ] = useState<PartnerType[]>( [] )
  const [ formData, setFormData ] = useState<FormData>({
    name: '',
    description: '',
    pointsValue: 0,
    giftValue: 0
  })
  const [ isRequestLoading, setIsRequestLoading ] = useState<boolean>( false )

  const loadPartners = useCallback( async () => {

    await api.get( 'gift-cards/partners' ).then( res => {
      setPartners( res.data )
    }).catch( console.log )

  }, [] )

  const submit = useCallback( async () => {
    setIsRequestLoading( true )
    await api.post( 'gift-cards', formData ).then(
      res => onSuccess && onSuccess( res.data ) )
    .catch( err => {
      const errors:ErrorResponseType = err.response.data
      if( !errors || !errors.messages ) return
      errors.messages.map( message => {
        M.toast({
          html: message,
          classes: 'red white-text rounded'
        })
        return message
      } )

      onError && onError()
    } )
    setIsRequestLoading( false )
  }, [ formData, onSuccess, onError ] )


  useEffect( () => {
    loadPartners()
  }, [ loadPartners ] )

  useEffect( () => () => {
    setIsRequestLoading( false )
    setFormData( {
      name: '',
      pointsValue: 0,
      giftValue: 0,
      description: ''
    } )
  }, [] )

  return (
    <Container className='row'>
      <div className="col s12 m8 offset-m2 l6 offset-l3">
        <label className="active"> Parceiro </label>
        <Select
          onChange={ option => setFormData( data => ( { ...data, partner: option.value.id } ))}
          options={ partners.map( partner => (
            {
              value: partner,
              label: partner.name
            }
          ) ) }
        />
      </div>

      <div className="col s12 m8 offset-m2 l6 offset-l3 input-field">
        <label className="active"> Nome do cartão </label>
        <input type="text" value={ formData.name.toString() }
          onChange={ ( e ) =>
            setFormData( data =>
              ( {
                ...data,
                name:  e.target.value
              } )
            )
          }
        />
      </div>

      <div className="col s12 m8 offset-m2 l6 offset-l3 input-field">
        <label className="active"> Valor do Gift Card</label>
        <input type="number" value={ formData.giftValue.toString() }
          onChange={ ( e ) =>
            setFormData( data =>
              ( {
                ...data,
                giftValue: parseFloat( e.target.value )
              } )
            )
          }
        />
      </div>

      <div className="col s12 m8 offset-m2 l6 offset-l3 input-field">
        <label className="active">Pontos para resgate</label>
        <input type="number" value={ formData.pointsValue.toString() }
          onChange={ ( e ) =>
            setFormData( data =>
              ( {
                ...data,
                pointsValue: parseInt( e.target.value )
              } )
            )
          }
        />
      </div>
      <div className="col s12 m8 offset-m2 l6 offset-l3 input-field">
        <label className="active">Descrição</label>
        <textarea
          className="materialize-textarea"
          value={ formData.description }
          onChange={ e => setFormData( data => ({ ...data, description: e.target.value }))}
        />
      </div>
      <div className="col s12 center">
        {
          !isRequestLoading ?
          <Button action={ submit } >Salvar</Button>
          :
          <Preloader />
        }
      </div>
    </Container>
  )
}


const Container = styled.div`
`

interface FormData {
  pointsValue: number,
  giftValue: number,
  name: string,
  description: string
}

interface Props {
  onSuccess?: ( data:GiftCardType ) => void
  onError?: () => void,
  giftcard?: any
}

export { NewGiftCardForm }
