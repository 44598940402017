import React, { useCallback } from 'react'
import styled from 'styled-components'
import api from '../api'
import useSetUser from '../hooks/useSetUser'
import { COLORS } from '../utils/constants'
import M from 'materialize-css'
import { useHistory } from 'react-router-dom'

const LogoutButton = () => {

  const setUser = useSetUser()
  const history = useHistory()
  const doLogout = useCallback( async () => {

    await api.post('logout').then( res => {
      res.data?.message && M.toast({
        html: res.data.message
      })
      setUser( undefined )
      history.push( '/login' )
    } ).catch( console.log )

  }, [ setUser, history ] )

  return (
    <Button onClick={ doLogout }>
      <i className="material-icons white-text">exit_to_app</i>
      <Helper> Clique para sair </Helper>
    </Button>
  )
}


const Helper = styled.span`
  overflow: hidden;
  max-width: 0;
  width: fit-content;
  margin: auto;
  background-color: white;
  box-shadow: 0 0 10px -5px black;
  border-radius: 4px;
  white-space: nowrap;
  position: absolute;
  top: calc( 100% - 5px );
  right: 15px;
  z-index: -1;
  transition: all 200ms;
`
const Button = styled.span`
  top: 20px;
  right: 20px;
  position: fixed;
  z-index: 99;
  cursor: pointer;

  &:hover{
    i{
      transition: all 200ms;
      background-color: ${COLORS.APP_GREEN};
    }
    span{
      transition: all 200ms;
      max-width: max-content;
      padding: 5px 10px;
    }
  }

  i{
    transition: all 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    background-color: red;
    border-radius: 50%;
    box-shadow: 0 0 10px -5px black;
  }
`


export { LogoutButton }
