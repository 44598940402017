import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../../api'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'
import { GiftCardType } from '../../../types/GiftCardType'
import { GiftCard as Card } from '../../../components/GiftCard'
import usePagination from '../../../hooks/usePagination'
import { GiftCardCodeType } from '../../../types/GiftCardCodeType'
import { Button } from '../../../components/Button'
import { Modal } from '../../../components/Modal'
import { modalOptions } from '../../../utils/modalOptions'
import { NewGiftCardCodeForm } from '../../../components/NewGiftCardCodeForm'
import { InfiniteScrollTable } from '../../../components/InfiniteScrollTable'


const GiftCard = () => {

  const { id } = useParams<{id: string}>()

  const [ giftCard, setGiftCard ] = useState<GiftCardType>()
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>( false )

  const pagination = usePagination<GiftCardCodeType>( `/gift-cards/${id}/codes` )

  const loadGiftCard = useCallback( async () => {
    await api.get( `/gift-cards/${id}` ).then( res => {
      setGiftCard( res.data )
    }).catch( console.log )
  }, [ id ] )

  useEffect( () => {
    loadGiftCard()

    return ( ) => {
      setIsModalOpen( false )
    }
  }, [loadGiftCard] )


  return (
    <LoggedTemplate pageTitle="Gift Cards">
      <div className="row">
        <div className="col no-padding">
          { giftCard && <Card giftCard={ giftCard } disableLink/> }
        </div>
      </div>

      <div className="row">
        <Modal
          options={
            {
              ...modalOptions,
              dismissible: false,
              onCloseEnd: () => setIsModalOpen( false )
            }
          }
          footer={ () =>
            <a href="#!" className="left modal-close btn-flat">Cancelar</a>
          }
          isOpen={ isModalOpen }
          trigger={ ( ...props:any ) => (
            <Button action={ () => {
              setIsModalOpen( open => !open )
            } }>
              Adicionar código
            </Button>
          )}
        >
          <NewGiftCardCodeForm
            giftCard={ giftCard }
            onSuccess={ () => {
              setIsModalOpen( false )
              pagination.reset()
            }}
          />
        </Modal>
      </div>

      <div className="row">
        <InfiniteScrollTable
          striped
          headers={[
            '#id',
            'Código',
            'Panel',
            'Observação',
            'Data de expiração',
            'Participante',
            'Status'
          ]}
          pagination={ pagination }
          renderData={[
            ( data ) => <td width={ 65 }>#{ data.id }</td>,
            ( data ) => <td>{ data.code }</td>,
            ( data ) => <td>{ data.panel ?? '-' }</td>,
            ( data ) => <td>{ data.observation }</td>,
            ( data ) => <td>{data.expireDate}</td>,
            ( data ) => <td>{ data.user?.name ?? '-' }</td>,
            ( data ) => <td>{ data.status }</td>,

          ]}
          endMessage="Sem mais códigos"
          emptyMessage="Sem códigos cadastrados"
        />
      </div>
    </LoggedTemplate>
  )
}

export { GiftCard }
