import { uniqueId } from 'lodash'
import { encode } from 'querystring'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import { PartnerType } from '../types/PartnerType'
import { Preloader } from './PreLoader'

const NextsToExpire = ( { panel, limitDays }:Props ) => {

  const [ data, setData ] = useState<NextToExpire>( {
    dates: [],
    cards: []
  } )
  const [ loading, setLoading ] = useState< boolean >( false )
  const [ didMount, setDidMount ] = useState< boolean >( false )
  const start:Date = useMemo( () => new Date(), [ ] )
  const end:Date = useMemo( () => {
    const date = new Date()
    date.setDate( new Date().getDate() + ( limitDays ?? 0 ) )
    return date
  }, [ limitDays ] )

  const loadData = useCallback( async () => {
    setLoading( true )

    const url = '/stock/nexts-to-expire?' + encode( {
      panel,
      limit_days: limitDays
    } )

    await api.get( url ).then( res => {
      setData( res.data )
    }).catch( console.log )

    setLoading( false )

  }, [ panel, limitDays ] )

  useEffect( () => {
    didMount && loadData()
  }, [ loadData, didMount ] )

  useEffect( () => {
    setDidMount( true )
    return () => setDidMount( false )
  }, [ ] )

  if( loading ) return (
    <div className="row center">
      <Preloader size="big" />
    </div>
  )

  return(
    <div className="row">
      <div className="col s12">
        <Header>
          <Title>
            Vencimentos
            <Description>
              Próximos Vencimentos
            </Description>
          </Title>
          {
            limitDays &&
              <Period>
                { `${start.getDate()}/${start.getMonth() + 1} - ` }
                { `${end.getDate()}/${end.getMonth() + 1}` }
              </Period>
          }
        </Header>
        {
          data.cards.length < 1
          || data.dates.length < 1 ?
          <div className="row">
            <div className="col s12">
              <h5 className="center">Sem vencimentos para os filtros selecionados</h5>
            </div>
          </div>
          :
          <Table>
            <Dates>
              <div>Vencimento</div>
              {
                data.dates.map( d => (
                  <div key={ uniqueId() } className="red-text">{ d }</div>
                ))
              }
            </Dates>
            {
              data?.cards?.map( card => (
                <GiftCard key={ uniqueId() }>
                  <div> { card.name } </div>
                  { card.dates.map( date => (
                    <div key={ uniqueId() } >{ date.toExpire }</div>
                  ) )}
                </GiftCard>
              ))
            }
          </Table>
        }
      </div>
    </div>
  )
}

const Period = styled.div`
  padding: 10px 15px;
  color: #101010;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  background-color: #D9E0D7;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #262626;
`

const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  color: #101010;
  margin-bottom: 20px;
`

const Table = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  box-shadow: 0 3px 12px -5px;
  overflow-x: auto;
`

const Dates = styled.div`
  position: sticky;
  left: 0;
  z-index: 1;
  top: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  max-width: max-content;
  box-shadow: 5px 0 5px -5px;

  div{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 100%;

    &:nth-child( even ){
      /* background-color: #F6FAF5; */
      background-color: #ccc;
    }
    &:first-child{
      height: 50px;
      font-weight: bold;
    }
    height: 40px;
  }
`

const GiftCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100px;

  div{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 100%;
    text-align: center;

    &:nth-child( even ){
      /* background-color: #F6FAF5; */
      background-color: #ccc;
    }
    &:first-child{
      height: 50px;
      font-weight: bold;
    }
    height: 40px;
  }

`
type NextToExpire = {
  dates: string[]
  cards: {
    dates: {
      expireDate: string,
      toExpire: number
    }[],
    name: string,
    partner?: PartnerType
  }[]
}
interface Props {
  panel?: string,
  limitDays?: number
}

export { NextsToExpire }
