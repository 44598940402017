import { Reducer } from 'redux';
import { UserState, UserTypes } from './types';
import { loadInitialState } from './initialState';

const INITIAL_STATE: UserState = {
  data: loadInitialState(),
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.SET_USER:
      return {
        ...state, data: action.payload.data,
      };
    default:
      return state;
  }
};



export default reducer;
