import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../api'
import usePagination from '../../hooks/usePagination'
import { UserType } from '../../types/UserType'
import { parseDateTimeString } from '../../utils/functions'
import { InfiniteScrollTable } from '../InfiniteScrollTable'

const UserClaimList = ( { filters }:Props  ) => {

  const [ user, setUser ] = useState<UserType>()
  const { id } = useParams<{id: string}>()

  const pagination = usePagination<Claim>(`/claims/user/${id}`, { params: filters })

  const loadUserData = useCallback( async () => {

    await api.get( `user/${id}`).then( res => {
      setUser( res.data )
    }).catch( console.log )

  }, [ id ] )

  useEffect( () => {
    loadUserData()
  }, [loadUserData] )

  return (
    <>
      <div className="row">
        <div className="col s12">
          <Description>
            Usuário
          </Description>
          <Title>
            { user?.name }
          </Title>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <InfiniteScrollTable
            pagination={ pagination }
            headers={[
              'Fornecedor',
              'Cartão',
              'Resgate',
              'Validade',
              'Código',
              'Painel',
            ]}
            renderData={[
              data => <td>{data?.card?.partner?.name}</td>,
              data =>
              (
                <td>
                  R${ data?.card?.giftValue } / { data?.card?.pointsValue ?? 0 }
                </td>
              ),
              data => <td> { data?.buyDate ?? '-' } </td>,
              data => <td>{data?.card?.expireDate ?? '-' }</td>,
              data => <td>{data?.code                    }</td>,
              data => <td>{data?.panel            ?? '-' }</td>,
            ]}
          />
        </div>
      </div>
    </>
  )

}


const Title = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #262626;
  margin-bottom: 20px;
`

const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 28px;
  color: #101010;
`

interface Props {
  filters?: {
    panel?: string
  }
}

interface Claim{

}


export { UserClaimList }
