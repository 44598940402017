import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { GiftCard } from '../../../components/GiftCard'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'
import { NewGiftCard } from '../../../components/NewGiftCard'
import { Modal } from '../../../components/Modal'
import styled from 'styled-components'
import { NewGiftCardForm } from '../../../components/NewGiftCardForm'
import { GiftCardType } from '../../../types/GiftCardType'
import api from '../../../api'
import { ContextMenu } from '../../../components/ContextMenu'
import { ConfirmModal } from '../../../components/ConfirmModal'
import { useHistory, useRouteMatch } from 'react-router-dom'

const GiftCards = () => {

  const [ isModalOpen, setIsModalOpen ] = useState<boolean>( false )
  const { url } = useRouteMatch()
  const history = useHistory()
  const [ cards, setCards ] = useState<GiftCardType[]>([])


  const deleteGiftCard = useCallback( async ( card:GiftCardType ) => {

    await api.delete( `gift-cards/${ card.id }`)
      .then( res => {
        setCards( cards => cards.filter( ( card ) => card.id !== res.data.id ) )
        M.toast({
          html: '<strong>Gift Card excluido com sucesso</strong>',
          classes: 'green rounded white-text'
        })
      } )
      .catch( console.log )

  }, [] )

  const loadCards = useCallback( async () => {

    await api.get( 'gift-cards' ).then( res => {
      setCards( res.data )
    }).catch( console.log )
  }, [] )

  useEffect( () => {
    loadCards()
  }, [ loadCards ] )

  return (
    <LoggedTemplate pageTitle="Gift Cards">
      <div className="row">
        <div className="col" style={{ marginBottom: 50 }}>
          <Modal
            isOpen={ isModalOpen }
            onOpen={ () => setIsModalOpen( true ) }
            trigger={ NewGiftCard }
            footer={ () =>
              <a href="#!" className="left modal-close btn-flat">Cancelar</a>
            }
          >
            <ModalContent>
              <NewGiftCardForm onSuccess={ ( data ) => {
                setCards( cards => [ data, ...cards ] )
                setIsModalOpen( false )
              } }/>
            </ModalContent>
          </Modal>
        </div>
        { cards.map( card => (
          <div  key={ uniqueId() } className="col">
            <ContextMenu
              trigger={ <GiftCard htmlId={ card.id } giftCard={ card } /> }
              options={[
                {
                  onClick: () => history.push(`${url}/${card.id}`),
                  text: <>Detalhes</>
                },
                {
                  text: <ConfirmModal
                    onConfirm={ () => deleteGiftCard( card ) }
                    trigger={ <span style={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                    }}> Deletar </span> }
                  />
                },
              ]}
            />
          </div>
        ) ) }
      </div>
    </LoggedTemplate>
  )
}

const ModalContent = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export { GiftCards }
