import React, { useState } from 'react'
import { NextsToExpire } from '../../../components/NextsToExpire'
import { PanelFilter } from '../../../components/PanelFilter'
import { StockByPartnerTable } from '../../../components/StockByPartnerTable'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'

const Stock = () => {

  const [ panel, setPanel ] = useState<string>('')
  return (
    <LoggedTemplate
      pageTitle="Estoque"
      pageDescription="Estoque dos gift cards disponíveis."
      filters={ <PanelFilter onChange={ panel => setPanel( panel ) } /> }
    >
      <div className="row">
        <StockByPartnerTable panel={ panel }/>
      </div>
      <div className="row">
        <NextsToExpire panel={ panel }/>
      </div>
    </LoggedTemplate>
  )
}

export { Stock }
