import fileDownload from 'js-file-download'
import { filter, uniqueId } from 'lodash'
import { encode } from 'querystring'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../api'
import { Button } from '../../../components/Button'
import { ClaimsList } from '../../../components/ClaimsList'
import { PanelFilter } from '../../../components/PanelFilter'
import { PeriodFilter } from '../../../components/PeriodFilter'
import { Preloader } from '../../../components/PreLoader'
import { AutoSearchBar } from '../../../components/SearchInput'
import { Select } from '../../../components/Select'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'
import { PartnerType } from '../../../types/PartnerType'
import { RegionType } from '../../../types/Region'


const Claims = () => {

  const [ filters, setFilters ] = useState<any>({})
  const [ partners, setPartners ] = useState<PartnerType[]>( [] )
  const [ regions,  setRegions ]  = useState<RegionType[]>( [] )
  const [ didMount, setDidMount ] = useState<boolean>( false )
  const [ Downloading, setDownloading ] = useState<JSX.Element>(  )

  const loadPartners = useCallback( async () => {

    await api.get( '/gift-cards/partners' ).then( res => {
      setPartners( res.data )
    }).catch( console.log )

  }, [] )

  const loadRegions = useCallback( async () => {

    await api.get( '/regions' ).then( res => {
      setRegions( res.data )
    }).catch( console.log )

  }, [] )

  const exportCsv = useCallback( async () => { 
    setDownloading( () => <Preloader size="small" /> )
    const params = { 
      ...filters,
      download: 1
    }
    await api.get( '/claims?' + encode( params ), { responseType: 'blob' } ).then( res => { 
      fileDownload( res.data, `resgates-${Math.floor( new Date().getTime() / 1000 ) }.csv` )
      setDownloading( undefined )
    } );
    
  }, [ filters ] )


  useEffect( () => {
    loadPartners()
    loadRegions()
  }, [ loadPartners, loadRegions ] )

  useEffect( () => {
    setDidMount( true )
    return () => setDidMount( false )
  }, [] )

  return (
    <LoggedTemplate
      pageTitle="Resgates"
      pageDescription="Resgates efetuados pelos usuários."
      filters={ <PanelFilter onChange={ panel => {
        didMount && setFilters( (filters:any) => ({ ...filters, panel: panel }) )
      } }/> }
    >
      <div className="row">
        <div className="col">
          <label>Bandeira</label>
          <Select
            onChange={ option => {
              setFilters( (filters:any) => ({ ...filters, partner: option.value?.id }) )
            }}
            options={ [
              {
                label: 'Todos',
                value: null
              },
              ...partners.map( partner => ({
                value: partner,
                label: partner.name
              }) )
            ] }
          />
        </div>
        <div className="col">
          <label>Período</label>
          <PeriodFilter
            onChange={ period => {
              setFilters( (filters:any) => ({ ...filters, ...period }) )
            }}
          />
        </div>
        <div className="col">
          <label>Região</label>
          <Select
            onChange={ option => {
              setFilters( (filters:any) => ({ ...filters, region: option.value }) )
            }}
            options={ [
              {
                label: 'Todos',
                value: null
              },
              ...regions.map( region => ({
                value: region.name,
                label: region.displayName
              }) )
            ] }
          />
        </div>
      </div>
      <div className="row">
        <div className="col s12 m3">
          <AutoSearchBar
            onChange={ text => setFilters( ( f:any ) => ( { ...f, search: text } ) ) }
          />
        </div>
        <div className="col s12 m3 center">
          { Downloading ?? <Button action={ exportCsv }> Exportar csv</Button> }
        </div>
      </div>
      <ClaimsList filters={ filters } />
    </LoggedTemplate>
  )
}


export { Claims }
