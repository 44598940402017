import api from "../api"
import store from "../store"
import { setUserState } from "../store/ducks/user/actions"


const parseJwtToken = ( token:string ):any => {

  const data = token.split('.').map( ( line: string, index:number ) => {
    if( index > 1 ) return null
    const data:any = JSON.parse( atob( line ) )
    return data
  })

  return data
}

const refreshToken = async ( ):Promise< string | undefined > => {

  let data = await api.post(`refresh`).then( res => {
    return res.data
  }).catch( console.log ) 
  
  store.dispatch( setUserState( data && { access_token: data?.access_token } ) )
  return data?.access_token
}

const calcPercentage = ( amount:number, total:number ):number => {

  if( total < 1 ) total = 1

  return 100 / total * amount
}

const parseDateTimeString = ( dateStr:string ):Date => {

  const [ date, hours ]= dateStr.split( ' ' )
  const [ year, month, day ] = date.split( '-' )
  const [ hour, minute, second ] = hours.split( ':' )

  return new Date(
    year   ? parseInt( year ) : 0 ,
    month  ? parseInt( month ) - 1 : 0,
    day    ? parseInt( day ) : 0,
    hour   ? parseInt( hour ) : 0,
    minute ? parseInt( minute ) : 0,
    second ? parseInt( second ) : 0
  )
}

export {
  parseJwtToken,
  refreshToken,
  calcPercentage,
  parseDateTimeString
}

