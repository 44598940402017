import { action } from 'typesafe-actions';
import { LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import { UserTypes, User } from './types';

export const setUserState = ( data?: User ) => {
  if( !data ){
    localStorage.removeItem( LOCAL_STORAGE_KEYS.USER )
  }else{
    localStorage.setItem( LOCAL_STORAGE_KEYS.USER, JSON.stringify( data ) )
  }
  return action( UserTypes.SET_USER, { data } );
}
