import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../utils/constants'

const Select = ( { options, onChange }:Props ) => {

  const [ isOpen, setIsOpen ]     = useState<boolean>( false )
  const [ selected, setSelected ] = useState<Option | undefined>( )
  const ref = useRef<any>( null )

  const handleOutsideClick = useCallback( ( e:MouseEvent ) => {
    if( !ref ) return

    if( !ref.current?.contains( e.target ) ) setIsOpen( false )

  }, [ ref ] )

  const selectOption = useCallback( ( option:Option ) => {

    options?.map( o => ( { ...o, selected: false } ) )
    option.selected = true
    setSelected( option )
    setIsOpen( false )
    onChange && onChange( option )

  }, [ onChange, options  ] )

  useEffect( () => {

    if( !selected && options && options.length > 0 ){
      selectOption( options[ 0 ] )
    }
    options && options.map( o => {
      if( o.selected ) selectOption( o )
      return o
    } )
  }, [ options, selectOption, selected ] )

  useEffect( () => {

    document.addEventListener( 'click', handleOutsideClick )

    return () => {
      document.removeEventListener( 'click', handleOutsideClick )
    }

  }, [ handleOutsideClick ])

  return (
    <Container ref={ ref }>
      <Selected onClick={ () => setIsOpen( isOpen => !isOpen ) }>
        { selected?.label }
      </Selected>
      <ArrowIcon className="material-icons" open={ isOpen } >
        keyboard_arrow_down
      </ArrowIcon>
      <OptionList open={ isOpen } optionsCount={ options ? options.length : 0 }>
        { options && options.map( option => (
          <OptionListItem key={ uniqueId() } onClick={ () => selectOption( option ) } >
            { option.label }
          </OptionListItem>
        ) ) }
      </OptionList>
    </Container>
  )
}

const Container = styled.div`
  min-width: 170px;
  height: 40px;
  border: 1px solid #D7D8DB;
  display: flex;
  align-items: center;
  position: relative;
`


const ArrowIcon = styled.i<{open?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  position: absolute;
  height: 20px;
  width: 20px;
  top: calc( 50% - 10px );
  right: 10px;
  z-index: 10;
  pointer-events: none;
  color: ${ COLORS.APP_GREEN };
  transition: all 150ms;
  transform: ${ props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const Selected = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  cursor: pointer;
  word-wrap: nowrap;
`

type OptionsListType = {
  open?: boolean,
  optionsCount?: number
}
const OptionList = styled.div<OptionsListType>`
  position: absolute;
  top: calc( 100% - 1px );
  left: -1px;
  max-height: ${ props => props.open ? '300px' : '0' };
  overflow: ${ props => props.open ? 'auto' : 'hidden' };
  z-index: 10;
  background-color: white;
  min-width: calc( 100% + 2px);
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 150ms;
  border: ${ props => props.open ? '1px solid #D7D8DB' : 'none' };
  border-top: 0;
`

const OptionListItem = styled.div`
  padding: 10px 15px;
  min-width: 100%;
  width: max-content;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  flex: 1;
  &:hover{
    background-color: ${ COLORS.APP_GREEN }
  }
  &:not(:last-child){
    border-bottom: 1px solid #D7D8DB;
  }
`
interface Option {
  value: any,
  label:string,
  selected?: boolean
}
interface Props{
  options?: Option[],
  onChange?: ( option:Option ) => any
}

export { Select }
