import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import { ErrorResponseType } from '../types/ErrroResponseType'
import { Button } from './Button'
import M from 'materialize-css'
import { PartnerType } from '../types/PartnerType'
import { TwitterPicker } from 'react-color'
import { GiftCard } from './GiftCard'


const NewPatnerForm = ( { onError, onSuccess, ...props }:Props ) => {

  const [ formData, setFormData ] = useState<FormData>()
  const [ name, setName ] = useState<string>('')
  const [ cardBackground, setCardbackground ] = useState<File>()
  const [ cardFontColor, setCardFontColor ] = useState<string>('white')
  const [ isRequestLoading, setIsRequestLoading ] = useState<boolean>( false )
  const [ tempBackground, setTempBackground ] = useState<string>('#000000')

  useEffect( () => {

    const formData = new FormData()

    if( cardBackground !== undefined ){
      formData.append( 'cardBackground', cardBackground, cardBackground.name )
    }
    formData.append( 'cardFontColor', cardFontColor )
    formData.append( 'name', name )

    setFormData( formData )
  }, [ cardBackground, cardFontColor, name ] )

  const handleImageChange = useCallback( ( event:ChangeEvent<HTMLInputElement> ) => {
    const {target} = event
    const {files} = target

    if (files && files[0]) {
      var reader = new FileReader()

      reader.onloadstart = () => setIsRequestLoading( true )

      reader.onload = event => {
        const tempUrl = event.target?.result ?? '#000000'
        if( typeof tempUrl === 'string' ) {
          if( tempUrl.match( '^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})' ) ) setTempBackground( tempUrl )
          else setTempBackground( `url("${ tempUrl }")`)
        }
        setCardbackground( files[0] )
        setIsRequestLoading( false )
      }

      reader.readAsDataURL(files[0])
    }
  }, [] )

  const submit = useCallback( async () => {

    setIsRequestLoading( true )

    await api.post( 'gift-cards/partners', formData ).then(
      res => onSuccess && onSuccess( res.data ) )
    .catch( err => {
      const errors:ErrorResponseType = err.response.data
      if( !errors || !errors.messages ) return
      errors.messages.map( message => {
        M.toast({
          html: message,
          classes: 'red white-text rounded'
        })
        return message
      } )

      onError && onError()
    } )
    setIsRequestLoading( false )
  }, [ formData, onSuccess, onError ] )

  useEffect( () => () => {
    setIsRequestLoading( false )
  }, [] )

  return (
    <Container>

      <div className="row">
        <label> Nome da bandeira </label>
        <input type="text" value={ name }
          onChange={ ( e ) => setName( e.target.value ) }
        />
      </div>

      <div className="row">
        <label className=""> Cor da fonte no gift card</label>
      </div>
      <div className="row">
        <TwitterPicker
          colors={[
            '#fffb00',
            '#FFFFFF',
            '#6AD600',
            '#ff0000',
            '#00fff7',
            '#0000ff',
            '#000000'
          ]}
          color={ cardFontColor }
          onChange={ color => setCardFontColor( color.hex ) }
        />
      </div>

      <div className="row">
        <div className="col s12">
          <label htmlFor="cardBackground">
            Imagem de fundo ( clique para selecionar )
            <GiftCard
              disableLink
              giftCard={{
                id: 1,
                pointsValue: 1000,
                giftValue: 50,
                partner: {
                  cardBackground: tempBackground,
                  name: name,
                  cardFontColor: cardFontColor
                }
              }}
            />
          </label>
        </div>
      </div>

      <div className="">
        <input
          style={{
            display: 'none'
          }}
          id="cardBackground"
          type="file"
          accept="image/*"
          capture="camera"
          onChange={ handleImageChange }
        />
      </div>

      <div className="row">
        {
          !isRequestLoading ?
          <Button action={ submit } >Salvar</Button>
          :
          <div className="preloader-wrapper small active">
            <div className="spinner-layer spinner-green-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div><div className="gap-patch">
                <div className="circle"></div>
              </div><div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        }
      </div>

    </Container>
  )
}


const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

interface Props {
  onSuccess?: ( data:PartnerType ) => void
  onError?: () => void
}

export { NewPatnerForm }
