import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MONTHS } from '../utils/constants'
import { Select } from './Select'

const YearFilter = ( { onChange: onSelect }:Props ) => {

  const [ itens, setItens ] = useState<YearFilterType[]>( [] )
  const period = useMemo<number>( () => 3, [] )

  const loadYears = useCallback( ( ) => {
    const date = new Date()
    date.setFullYear( date.getFullYear() - period )

    let itens:YearFilterType[] = []

    const loop = () => {
      while( date.getTime() <= new Date().getTime() ){

        const label:string = `${date.getFullYear()}`
        const year = date.getFullYear().toString()
        date.setFullYear( date.getFullYear() + 1 )
        itens.push( { label, year } )
      }
    }

    loop()

    setItens( itens )
  }, [ period ] )

  useEffect( ( ) => {
    loadYears()
  }, [ loadYears ] )

  return (
    <Select
      onChange={ option => {
          onSelect && onSelect(  option?.value ?? '' )
      }}
      options={[
        {
          label: 'Todos',
          value: null
        },
        ...itens.map( item => (
            {
              label: item.label.toString().toUpperCase(),
              value: item.year ?? ''
            }
          )
        )
      ] }
    />
   )
}

interface Props{
  onChange?: ( year: string ) => void
}

export interface YearFilterType{
  label: string
  year:  string,
}

export { YearFilter }
