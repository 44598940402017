import React from 'react'
import styled from 'styled-components'
import { PartnerType } from '../types/PartnerType'
import { COLORS } from '../utils/constants'
import { GiftCard } from './GiftCard'

const PartnerCard = ( { partner, disableLink, ...props }:Props ) => {

  return (
    <GiftCard
      { ...props }
      giftCard={{
        id: 0,
        giftValue: 0,
        pointsValue: 0,
        partner: partner
      }}
    />
  )
}

interface Props {
  partner: PartnerType,
  disableLink?: boolean
}

export { PartnerCard }


