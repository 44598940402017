import React, {  useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import { ErrorResponseType } from '../types/ErrroResponseType'
import { Button } from './Button'
import { Select } from './Select'
import M from 'materialize-css'
import { GiftCardType } from '../types/GiftCardType'
import { Preloader } from './PreLoader'
import Datepicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Panel } from '../types/Panel'

const NewGiftCardCodeForm = ( { onError, onSuccess, giftCard, ...props }:Props ) => {

  const [ formData, setFormData ] = useState<FormData[]>([])
  const [ codes, setCodes       ] = useState<string[]>([])
  const [ expDate, setExpDate   ] = useState<Date>( new Date() )
  const [ isRequestLoading, setIsRequestLoading ] = useState<boolean>( false )
  const [ panels, setPanels     ] = useState<Panel[]>( [] )
  const [ panel, setPanel       ] = useState<string>('PNC')
  const textareaRef = useRef<any>( null )

  const loadPanels = useCallback( async () => {
    await api.get( '/panels' ).then( res => {
      setPanels( res.data )
    }).catch( console.log )
  }, [] )

  useEffect( () => {
    setFormData( codes.map( code => (
      {
        code,
        panel,
        expireDate: expDate.getFullYear().toString()
          + '-' + ("0" + ( expDate.getMonth() + 1 ) ).slice(-2).toString()
          + '-' + ("0" + expDate.getDate() ).slice(-2).toString()
      }
    )))
  }, [ panel, expDate, codes ])

  const submit = useCallback( async () => {

    if( !giftCard ) return

    setIsRequestLoading( true )

    await api.post( `gift-cards/${giftCard.id}/codes`, formData ).then( res => {
      onSuccess && onSuccess( res.data )
      setFormData( [] )
      if( textareaRef ) textareaRef.current.value = ""
    })
    .catch( err => {
      const errors:ErrorResponseType = err.response.data
      if( !errors || !errors.messages ) return
      errors.messages.map( message => {
        M.toast({
          html: message,
          classes: 'red white-text rounded'
        })
        return message
      } )

      onError && onError()
    } )
    setIsRequestLoading( false )
  }, [ formData, onSuccess, onError, giftCard ] )

  useEffect( () => {
    loadPanels()
  }, [ loadPanels ] )

  useEffect( () => () => {
    setIsRequestLoading( false )
  }, [] )

  return (
    <Container>
      <div className="row">
        <div className="col s12 ">
          <div className="col s6">
            <label> Painel </label>
            <Select
              onChange={ option => setPanel( option.value ) }
              options={ panels.map( panel => (
                {
                  value: panel.name,
                  label: panel.name
                }
              ) ) }
            />
          </div>
          <div className="col s6">
            <label> Data de Expiração </label>
            <Datepicker
              selected={ expDate }
              dateFormat="d/M/Y"
              onChange={ date => {
                if( date && 'getTime' in date ){
                  setExpDate( date )
                }
              } }
            />
          </div>
        </div>
        <div className="col s12">
          <div className="col s12 input-field">
            <label>Códigos ( Um em cada linha ) </label>
            <textarea
              ref={ textareaRef }
              onChange={ e => {
                setCodes( () => e.target.value
                  .split('\n')
                  .filter( code => code !== "" )
                )
              }}
              className="materialize-textarea"></textarea>
          </div>
        </div>
      </div>

      <div className="row">
        {
          !isRequestLoading ?
          <Button action={ submit } >Salvar</Button>
          :
          <Preloader />
        }
      </div>
    </Container>
  )
}


const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

interface FormData {
  code: string,
  panel: string,
  expireDate?: string
}

interface Props {
  onSuccess?: ( data:GiftCardType ) => void
  onError?: () => void,
  giftCard?: GiftCardType
}

export { NewGiftCardCodeForm }
