import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MONTHS } from '../utils/constants'
import { Select } from './Select'


const PeriodFilter = ( { onChange: onSelect }:Props ) => {

  const [ itens, setItens ] = useState<PeriodFilterType[]>( [] )
  const period = useMemo<number>( () => 1, [] )

  const loadMonths = useCallback( ( ) => {
    const date = new Date()
    date.setFullYear( date.getFullYear() - period )

    let itens:PeriodFilterType[] = []

    const loop = () => {
      while( date.getTime() <= new Date().getTime() ){

        const label:string = `${ MONTHS[date.getMonth()] } / ${date.getFullYear()}`

        const from:Date = new Date( date.getFullYear(), date.getMonth(), 1 )
        const to:Date = new Date( date.getFullYear(), date.getMonth() + 1, 0 )

        const period:any = {
          from: `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`,
          to: `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`
        }

        const obj:PeriodFilterType = {
          label,
          period
        }
        date.setMonth( date.getMonth() + 1 )
        itens.push( obj )
      }
    }

    loop()

    setItens( itens )
  }, [ period ] )

  useEffect( ( ) => {
    loadMonths()
  }, [ loadMonths ] )

  return (
    <Select
      onChange={ option => {
          onSelect && onSelect( {
            from_date: option?.value?.from ?? '',
            to_date: option?.value?.to ?? ''
          } )
      }}
      options={[
        {
          label: 'Todos',
          value: null
        },
        ...itens.map( item => (
            {
              label: item.label.toString().toUpperCase(),
              value: item.period
            }
          )
        )
      ] }
    />
   )
}

interface Props{
  onChange?: (
    period:{
      from_date: string,
      to_date: string
    } ) => void
}

export interface PeriodFilterType{
  label: string
  period: {
    from: string,
    to: string
  }
}

export { PeriodFilter }
