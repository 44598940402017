import React from 'react'
import {
  BrowserRouter as Router ,
  Switch,
  Route,
} from 'react-router-dom'
import useIsLogged from '../hooks/useIsLogged'
import { LoggedTemplate } from '../Layout/LoggedTemplate'
import { Claims } from './pages/Claims'
import { UserClaims } from './pages/Claims/UserClaims'
import { General } from './pages/General'
import { GiftCards } from './pages/GiftCards'
import { GiftCard } from './pages/GiftCards/GiftCard'
import { Login } from './pages/Login'
import { NotFound } from './pages/NotFound'
import { Partners } from './pages/Partners'
import { Stock } from './pages/Stock'
import { Tendencies } from './pages/Tendencies'
import { Users } from './pages/Users'
import {ExpiratedCodes } from './pages/ExpiratedCodes'
import PrivateRoute from './PrivateRoute'


const Routes = ( ) => {

  const isLogged = useIsLogged()

  return (
    <Router basename={ process.env.REACT_APP_BASE_PATH ?? '/' }>
      <Switch>

        <Route exact path="/login" >
          <Login />
        </Route>

        <PrivateRoute history exact path="/estoque" redirect="/login">
          <Stock />
        </PrivateRoute>

        <PrivateRoute history exact path="/resgates/usuario/:id" redirect="/login">
          <UserClaims />
        </PrivateRoute>

        <PrivateRoute history exact path="/resgates" redirect="/login">
          <Claims />
        </PrivateRoute>

        <PrivateRoute history exact path="/tendencias" redirect="/login">
          <Tendencies />
        </PrivateRoute>

        <PrivateRoute history exact path="/gift-cards/:id" redirect="/login">
          <GiftCard />
        </PrivateRoute>

        <PrivateRoute history exact path="/gift-cards" redirect="/login">
          <GiftCards />
        </PrivateRoute>

        <PrivateRoute history exact path="/bandeiras" redirect="/login">
          <Partners />
        </PrivateRoute>

        <PrivateRoute history exact path="/expirados" redirect="/login">
          <ExpiratedCodes />
        </PrivateRoute>


        <PrivateRoute history exact path="/usuarios-admin" redirect="/login">
          <Users />
        </PrivateRoute>

        <PrivateRoute history exact path="/" redirect="/login" >
          <General />
        </PrivateRoute>

        <Route path="*" >
          { isLogged ?
            <LoggedTemplate >
              <NotFound />
            </LoggedTemplate>
            :
            <NotFound />
          }
        </Route>
      </Switch>
    </Router>
  )
}

export { Routes }
