import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../utils/constants'

const NewGiftCard = ( { ...props }:any ) => {

  return (
    <Container { ...props } >
      <Icon className="material-icons">add</Icon>
      Criar novo cartão
    </Container>
  )
}

type ContainerProps = {
  backgroud?: string
  fontColor?: string
}
const Container = styled.div<ContainerProps>`
  padding: 16px;
  background: ${ props => props.backgroud ?? 'white' };
  width: 260px;
  height: 145px;
  border-radius: 8px;
  color: ${ props => props.fontColor ?? 'black' };
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${ COLORS.APP_GREEN };
  border-style: dashed;
  cursor: pointer;
`

const Icon = styled.i`
  font-size: 65px;
  color: ${ COLORS.APP_GREEN };
`

export { NewGiftCard }


