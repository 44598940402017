import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../utils/constants'

const PanelFilter = ( { onChange }:Props ) => {

  //'Total' | 'OOH' | 'Usage' | 'PNC'
  const [ selected, setSelected ] = useState<string>( '' )

  const select = useCallback( ( iten:string ) => {

    if( iten !== selected ){
      onChange && onChange( iten )
    }
    setSelected( iten )

  }, [ selected, onChange ] )

  return(
    <Container>
      <Label>Painel</Label>
      <Itens>
        <Iten
          active={ selected === ''}
          onClick={ () => select('') }
        >Total</Iten>
        <Iten
          active={ selected === 'OOH'}
          onClick={ () => select('OOH') }
        >OOH</Iten>
        <Iten
          active={ selected === 'Usage'}
          onClick={ () => select('Usage') }
        >Usage</Iten>
        <Iten
          active={ selected === 'PNC'}
          onClick={ () => select('PNC') }
        >PNC</Iten>
        {/* <Iten
          active={ selected === '-'}
          onClick={ () => select('-') }
        >Não Vinculado</Iten> */}
      </Itens>
    </Container>
  )

}


const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
`

const Label = styled.div`
  font-size: 14px;
  color: #262626;
  margin-bottom: 5px;
`

const Itens = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const Iten = styled.div<{active?:boolean}>`
  min-width: 116px;
  height: 38px;
  border: 1px solid #D7D8DB;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  transition: all 300ms;
  padding: 5px 10px;

  white-space: nowrap;

  background-color: ${ props => props.active ? COLORS.APP_GREEN : '' };

  &:hover{
    background-color: ${ COLORS.APP_GREEN };
    transition: all 300ms;
  }
`

interface Props {
  onChange?: ( panel:string ) => void
}

export { PanelFilter }
