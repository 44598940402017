import React from 'react';
import { InfiniteScrollTable } from '../../../components/InfiniteScrollTable';
import { LoggedTemplate } from '../../../Layout/LoggedTemplate';
import { GiftCardCodeType } from '../../../types/GiftCardCodeType'
import usePagination from '../../../hooks/usePagination'

const ExpiratedCodes = () => {

  const pagination = usePagination<GiftCardCodeType>( `expirated-codes` )

  return(
    <LoggedTemplate pageTitle='Côdigos expirados'>
      <div className="row">
        <InfiniteScrollTable
          striped
          headers={[
            '#id',
            'bandeira',
            'Código',
            'Panel',
            'Data de expiração',
            'Status',
          ]}
          pagination={ pagination }
          renderData={[
            ( data ) => <td width={ 65 }>#{ data.id }</td>,
            ( data ) => <td>{ data.card?.partner?.name }</td>,
            ( data ) => <td>{ data.code }</td>,
            ( data ) => <td>{ data.panel ?? '-' }</td>,
            ( data ) => <td>{data.expireDate }</td>,
            ( data ) => <td>{ data.status }</td>,
          ]}
          endMessage="Sem mais códigos"
          emptyMessage="Sem códigos cadastrados"
        />
      </div>
    </LoggedTemplate>
  );
}

export {ExpiratedCodes}
