import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../api'
import { COLORS } from '../utils/constants'
import { calcPercentage } from '../utils/functions'
import { Preloader } from './PreLoader'


const ClaimsByPartner = ( { panel }:Props ) => {

  const [ data, setData ] = useState<any>({})
  const [ loading, setLoading ] = useState<boolean>(false)

  const loadClaims = useCallback( async ( ) => {
    const filter = !panel ? '' : `panel=${panel}`
    setData( {} )
    setLoading( true )
    await api.get( `gift-cards/tendencies/claims-by-partner?${filter}`  ).then( res => {
      setData( res.data )
    }).catch( console.log )
    setLoading( false )
  }, [ panel ] )

  useEffect( () => {
    loadClaims()
  }, [ loadClaims ])

  return (
    <Container>
      Resgate por fornecedor
      <Itens>
        { loading && <Preloader /> }
        { data?.partners?.map( ( partner:any ) => {
            const percentage = calcPercentage( partner?.claimsCount ?? 0, data?.allClaims ?? 0 )
            return (
              <Iten key={ uniqueId() } >
                <ItenPercentage percentage={percentage} >
                  { percentage.toFixed( 2 ) }%
                </ItenPercentage>
                { partner?.name }
              </Iten>
            )
          }
        )}
      </Itens>
    </Container>
  )
}

const Itens = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DFDFDF;
  flex-direction: column;
  width: 100%;
`
const Iten = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 5px 10px;
  white-space: nowrap;

  &:nth-child(odd){
    background-color: #F6FAF5; //
  }
`

const ItenPercentage = styled.div<{percentage: number}>`
  font-size: 16px;
  font-weight: 400;

  &:after{
    content: '';
    height: 5px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    background-color: ${ COLORS.APP_GREEN };
    width: ${ props => props.percentage }%;
    position: absolute;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  color: #262626;
  font-weight: bold;
`

interface Props{
  panel?: string
}
export { ClaimsByPartner }
