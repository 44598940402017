import axios from 'axios'
import store from '../store'
import { setUserState } from '../store/ducks/user/actions'
import { LOCAL_STORAGE_KEYS } from '../utils/constants'
import { parseJwtToken, refreshToken } from '../utils/functions'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL ?? 'http://admin.kantarcolaboradores.com.br/api/v1' 
})

api.interceptors.request.use( async ( config ) => {

  let state = store.getState()
  if( !state || !state?.User?.data?.access_token )
    return config

  let token = null
  token = state?.User?.data?.access_token

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ ,parsedToken ] = parseJwtToken( state.User?.data?.access_token )

  console.log( new Date( parsedToken.exp * 1000 ) )
  if( !config.url?.endsWith('refresh') ){
    if( ( parsedToken.exp * 1000 - ( 5000 * 60  ) ) < new Date().getTime() ){
      token = await refreshToken( )
    }
  }

  state = store.getState()

  if( !state || !state?.User?.data?.access_token )
    return config

  config.headers = {
    ...config.headers,
    'Authorization': `Bearer ${token}`
  }

  return config
} )

api.interceptors.response.use( res => res, async err => {

  err?.response?.data?.messages?.map( ( error:string[] | string ) => {
    if( typeof error === 'string' ){
      M.toast({
        html: error,
        classes: 'white-text rounded red'
      })
    }else{
      error.map( e => M.toast({
          html: e,
          classes: 'white-text rounded red'
        })
      )
    }

    return error
  })

  return Promise.reject( err )
} )
export default api
