import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../utils/constants'
import { calcPercentage } from '../../utils/functions';

const BarGraphic = ( { data, labels, title, subTitle, maxValue, total }:Props ) => {

  const guides:number[] = useMemo( () => {

    let value = maxValue
    if( value < 30 ){
      value = 30
    }
    let itens:number[] = []
    const sliceSize = ( value + 10 ) / 4

    for( let i = 0; i < 5; i++ ){
      let int = Math.round( sliceSize * i / 10 * 10 )

      if( parseInt( int.toString().slice(-1) ) >= 5 )
        itens.push( Math.ceil( int / 10 ) * 10 )
      else
        itens.push( Math.floor( int / 10  ) * 10 )
    }
    return itens
  }, [ maxValue ] )

  return (
    <Container>
      <div className="row" style={{ margin: 0 }}>
        <Header>
          <Title>
            { title }
            <SubTitle>
              { subTitle }
            </SubTitle>
          </Title>
          <Total>
            Total <br /> { total }
          </Total>
        </Header>
        <div className="col no-padding">
        </div>
        <div className="col right valign-wrapper no-padding">
          { labels?.map( label => (
            <Label key={ uniqueId() } >
              { label.label }
              <span style={{
                backgroundColor: label.color ?? 'black'
              }} />
            </Label>
          ) ) }
        </div>
      </div>
      <Graphic>
        <Guide>
          { guides.map( label => (
              <span key={ uniqueId() }>{label}</span>
            ))
          }
          <GuideDivider />
        </Guide>
        { data?.map( info => (
          <GraphicGroup key={ uniqueId() } >
            <GraphicGroupLabel>
              { info.label }
            </GraphicGroupLabel>
            <Bars>
              { info.bars.map( bar => (
                <Bar
                  key={ uniqueId() }
                  style={{
                    maxWidth: `${calcPercentage( bar.amount ?? 0, maxValue + 10 ) }%`,
                    backgroundColor: bar.color ?? COLORS.APP_GREEN,
                    color: bar.fontColor ?? 'white'
                  }}
                >
                  { bar.amount }
                </Bar>
              ) ) }
            </Bars>
          </GraphicGroup>
        ) )}
      </Graphic>
    </Container>
  )
}

const Guide = styled.div`
  width: 100%;
  padding-left: 35px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 16px;
`

const GuideDivider = styled.div`
  position: absolute;
  width: calc( 100% - 35px);
  height: 1px;
  background-color: #707070;
  top: calc( 100% + 5px);
  left: 35px;
`

const Total = styled.div`
  padding: 5px 10px;
  color: #101010;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  background-color: #D9E0D7;
  text-align: center;
`

const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
`

const SubTitle = styled.span`
  font-size: 16px;
  font-weight: normal;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-of-type){
    margin-right: 10px;
  }
  font-size: 14px;

  span{
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const Container = styled.div`
  font-family: 'Open Sans';
  width: 100%;
`

const Bars = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

const Bar = styled.div`
  padding: 5px;
  min-height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  overflow: hidden;

  @keyframes animate {
    from {width: 0%;}
    to {width: 100%;}
  }

  animation: animate;
  animation-duration: 500ms;
  animation-iteration-count: 1;
`

const GraphicGroup = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: white;
  display: flex;
  margin-bottom: 10px;
`

const GraphicGroupLabel = styled.div`
  max-width: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  white-space: nowrap;
`

const Graphic = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`

export type ColumnGraphicData = {
  bars: {
    amount: number,
    color?: string,
    fontColor?: string
  }[],
  label: string,
}

type Props = {
  total?: number,
  maxValue: number
  title?: string,
  subTitle?: string,
  data?:ColumnGraphicData[],
  labels?:{
    color: string,
    label: string
  }[]
}

export { BarGraphic }
