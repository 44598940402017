import { uniqueId } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../api'
import { AdminUserCard } from '../../../components/AdminUserCard'
import { AdminUserForm } from '../../../components/AdminUserForm'
import { ConfirmModal } from '../../../components/ConfirmModal'
import { ContextMenu } from '../../../components/ContextMenu'
import { Modal } from '../../../components/Modal'
import { NewAdminUserCard } from '../../../components/NewAdminUserCard'
import { LoggedTemplate } from '../../../Layout/LoggedTemplate'
import { User } from '../../../store/ducks/user/types'
import { Panel } from '../../../types/Panel'


const Users = () => {

  const [ users, setUsers ] = useState<User[]>([])
  const [ panels, setPanels ] = useState<Panel[]>( [] )
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>( false )

  const loadPanels = useCallback( async () => {
    await api.get('panels').then( res => {
      setPanels( res.data )
    }).catch( console.log )
  }, [] )

  const loadUsers = useCallback( async () => {

    await api.get('admin-users').then( res => {
      setUsers( res.data )
    }).catch( console.log )

  }, [] )

  const desactivateUser = useCallback( async ( id:number ) => {

    await api.delete(`admin-users/${ id }`).then( res => {
      console.log( res.data )
    }).catch( console.log )

  }, [ ] )

  const makeUserAdmin = useCallback( async ( id:number ) => {

    await api.put(`admin-users/${ id }/make-admin`).then( res => {
      setUsers( prev => prev.map( user => {
        if( user.id !== id ) return user

        return res.data
      }))
    }).catch( console.log )

  }, [ ] )

  useEffect( ( ) => {
    loadUsers()
    loadPanels()
  }, [ loadUsers, loadPanels ] )

  return (
    <LoggedTemplate
      pageTitle="Usuários Admin"
    >
      <div className="row">
        <div className="col">
          <Modal
            trigger={ NewAdminUserCard }
            isOpen={ isModalOpen }
            onOpen={ () => setIsModalOpen( true ) }
            onClose={ () => setIsModalOpen( false ) }
          >
            <AdminUserForm panels={ panels } onSuccess={ data => {
              setUsers( prev => [ data, ...prev ])
              setIsModalOpen( false )
            }} />
          </Modal>
        </div>
        { users.map( user => (
          <div
            key={ uniqueId() }
            className="col">
              <ContextMenu
                trigger={ <AdminUserCard user={ user } /> }
                options={[
                  {
                    text: <Modal
                      trigger={ ( { ...props } ) => <div { ...props } > Editar </div> }
                    >
                      <AdminUserForm panels={ panels } userData={ user } />
                    </Modal>
                  },
                  {
                    text: <ConfirmModal
                      onConfirm={ () => makeUserAdmin( user.id ) }
                      trigger={ <span style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                      }}> Tornar Admin </span> }
                    />
                  },
                  {
                    text: <ConfirmModal
                      onConfirm={ () => desactivateUser( user.id ) }
                      trigger={ <span style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                      }}> Desativar </span> }
                    />
                  },
                ]}
              />
          </div>
        ))}
      </div>

    </LoggedTemplate>
   )
}

export { Users }
