import { uniqueId } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../utils/constants'
import { calcPercentage } from '../../utils/functions';

const graphicHeight = 300;
const ColumnGraphic = ( { data, labels, title, subTitle, maxValue }:Props ) => {

  return (
    <Container>
      <div className="row">
        <div className="col no-padding">
          <Title className="col s12 no-padding">
            { title }
          </Title>
          <SubTitle className="col s12 no-padding">
            { subTitle }
          </SubTitle>
        </div>
        <div className="col right valign-wrapper no-padding">
          { labels?.map( label => (
            <Label key={ uniqueId() } >
              { label.label }
              <span style={{
                backgroundColor: label.color ?? 'black'
              }} />
            </Label>
          ) ) }
        </div>
      </div>
      <div className="row">
        <Graphic>
          { data?.map( info => (
            <GraphicGroup key={ uniqueId() } >
              <Bars>
                { info.bars.map( bar => (
                  <Bar
                    key={ uniqueId() }
                    style={{
                      maxHeight: `calc( ${calcPercentage( bar.amount ?? 0, maxValue ) }% + 25px )`,
                      backgroundColor: bar.color ?? COLORS.APP_GREEN,
                      color: bar.fontColor ?? 'white'
                    }}
                  >
                    { bar.amount }
                  </Bar>
                ) ) }
              </Bars>
              <GraphicGroupLabel>
                { info.label }
              </GraphicGroupLabel>
            </GraphicGroup>
          ) )}
          <Divider />
        </Graphic>
      </div>
    </Container>
  )
}

const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
`

const SubTitle = styled.span`
  font-size: 16px;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-of-type){
    margin-right: 10px;
  }
  font-size: 14px;

  span{
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }
`

const Container = styled.div`
  font-family: 'Open Sans';
`

const Bars = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
`

const Bar = styled.div`
  padding: 5px;
  min-height: 25px;
  width: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  height: 100%;

  @keyframes animate {
    from {height: 0%;}
    to {height: 100%;}
  }

  animation: animate;
  animation-duration: 500ms;
  animation-iteration-count: 1;
`

const GraphicGroup = styled.div`
  padding: 0 10px;
  width: fit-content;
  min-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`

const GraphicGroupLabel = styled.div`
  min-height: 50px;
  max-height: 50px;
  width: 100% ;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  white-space: nowrap;
`

const Divider = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 50px;
  width: 100%;
  border-top: 1px solid;
`

const Graphic = styled.div`
  height: ${graphicHeight}px;
  width: 100%;
  overflow: auto;
  display: flex;
  position: relative;
`

export type ColumnGraphicData = {
  bars: {
    amount: number,
    color?: string,
    fontColor?: string
  }[],
  label: string,
}

type Props = {
  maxValue: number
  title?: string,
  subTitle?: string,
  data?:ColumnGraphicData[],
  labels?:{
    color: string,
    label: string
  }[]
}

export { ColumnGraphic }
