import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import api from '../../api'
import { Button } from '../../components/Button'
import { Preloader } from '../../components/PreLoader'
import useIsLogged from '../../hooks/useIsLogged'
import useSetUser from '../../hooks/useSetUser'
import { ErrorResponseType } from '../../types/ErrroResponseType'
import { COLORS } from '../../utils/constants'

const Login = ( { intent }:{ intent?: string } ) => {

  const [ hasError, setHasError ] = useState<boolean>( false )
  const [ email, setEmail ] = useState<string>( '' )
  const [ password, setPassword ] = useState<string>( '' )
  const [ loading, setLoading ] = useState<boolean>( false )
  const isLogged = useIsLogged()
  const history = useHistory()
  const setUser = useSetUser()

  const doLoggin = useCallback( async () => {
    setLoading( true )

    await api.post( 'login', { email, password } ).then( res => {
      setUser( res.data )
      history.push( intent ?? '/')
    }).catch( err => {

      setHasError( true )

      const errors:ErrorResponseType = err.response?.data
      if( !errors || !errors.messages ) return
      errors.messages.map( message => {
        M.toast({
          html: message,
          classes: 'red white-text rounded'
        })
        return message
      } )
    })

    setLoading( false )
  }, [ email, password, intent, setUser, history ] )


  const handleEnterKeyPress = useCallback( ( e:KeyboardEvent ) => {

    if( e.code.toString().toLowerCase() === 'enter' ) doLoggin()

  }, [ doLoggin ] )

  useEffect( ( ) => {
    document.title = 'Login - Kantar'
  }, [ ] )

  useEffect( () => {
    isLogged && history.push( '/' )
  }, [ history, isLogged ])

  useEffect( () => {
    document.addEventListener( 'keypress', handleEnterKeyPress )
    return () => document.removeEventListener( 'keypress', handleEnterKeyPress )
  }, [ handleEnterKeyPress ] )

  return (
    <Container>
      <AppName>
        Kantar
      </AppName>
      <Form>
        <div className="row">
          <div className="col s12">
            <InputField error={ hasError }>
              <label >Login</label>
              <input
                name="login"
                type="email"
                value={ email }
                onChange={ e => setEmail( e.target.value ) }
                className="browser-default"
              />
            </InputField>
          </div>
          <div className="col s12">
            <InputField error={ hasError }>
              <label >Senha</label>
              <input
                name="password"
                type="password"
                value={ password }
                onChange={ e => setPassword( e.target.value ) }
                className="browser-default"
              />
            </InputField>
          </div>


          <div className="col s12 center">
            { loading ?
              <Preloader />
              : <Button style={ { marginTop: 10 } }  action={ doLoggin } > Acessar </Button>
            }
          </div>
        </div>

      </Form>
    </Container>
  )
}
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Form = styled.div`
  width: calc( 100% - 30px);
  max-width: 300px;
  box-shadow: 0 4px 12px -5px;
  min-height: 200px;
  border-radius: 10px;
  padding: 30px 15px;
`

const InputField = styled.div<{error?: boolean}>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;

  > label, > input  {
    width: 100%;
  }

  label{
    font-size: 16px;
  }

  input{
    border-radius: 4px;
    height: 40px;
    padding-left: 10px;
    outline: none;
    border: none;
    margin-top:  5px;
    box-shadow: 0px 4px 8px -4px ${ props =>
      props.error ? 'red' : 'black'
    };
    transition: all 300ms;

    &:focus{
      box-shadow: 0px 4px 10px -4px blue;
      transition: all 300ms;
    }
  }
`

const AppName = styled.div`
  color: ${ COLORS.APP_GREEN };
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 20px;
`

export { Login }
