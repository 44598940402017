const modalOptions = {
  onOpenStart: () => undefined,
  onOpenEnd: () => undefined,
  onCloseStart: () => undefined,
  onCloseEnd: () => undefined,
  preventScrolling: true,
  inDuration: 250,
  outDuration: 250,
  opacity: 0.5,
  dismissible: false,
  startingTop: "4%",
  endingTop: "10%"
}


export { modalOptions }
