import React, { useCallback, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import styled from 'styled-components'
import api from '../../../api'
import { Preloader } from '../../../components/PreLoader'
import { Panel } from '../../../types/Panel'
import { COLORS, MONTHS } from '../../../utils/constants'

interface ResponseData{
  claims: number,
  months: {
    index: number,
    name: string,
    claims: number,
    panels: {
      name: string,
      claims: number,
    }[]
  }[],
  panels: Panel[]
}

const ClaimsByMonthGraphic = ( { year }:Props ) => {

  const [ data, setData ] = useState<ResponseData>( )
  const [ loading, setLoading ] = useState<boolean>( false )

  const loadData = useCallback( async () => {
    setLoading( true )
    await api.get('claims/by-month?year=' + year ?? '' ).then( res => {
      setData( res.data )
    }).catch( console.log )
    setLoading( false )
  }, [ year ] )

  useEffect( () => {
    loadData()
  }, [ loadData ] )

  return (
    <Container >
      {
        loading ?
          <div className="row center">
            <Preloader size='big' />
          </div>
          : !data ? <> Erro ao carregar </>
            :
            <Line
              height={ 100 }
              data={{
                labels: data?.months.map( month => MONTHS[month.index] ),
                datasets: data?.panels.map( panel => {
                  return {
                    label: panel.name,
                    borderColor: panel.colorInGraphic ?? COLORS.APP_GREEN,
                    backgroundColor: 'transparent',
                    data: data.months.map( month => {

                      const currentPanel = month.panels.find( p => p.name === panel.name )

                      if( !currentPanel ) return 30

                      return currentPanel.claims
                    })
                  }
                })
              }}
              options={{
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
              }}
            />
      }

    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 500px;
`

interface Props {
  year?: string
}

export { ClaimsByMonthGraphic }
