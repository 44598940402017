import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

const NotFound = () => {

  const route = useRouteMatch()

  useEffect( () => {
    console.log( route )
  }, [ route ] )

  return(
    <div className="container">
      <h3> Página não encontrada </h3>
    </div>
  )
}


export { NotFound }
