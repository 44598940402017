import React, { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import usePagination from '../../hooks/usePagination'
import { InfiniteScrollTable } from '../InfiniteScrollTable'

const ClaimsList = ( { filters }:Props  ) => {

  const pagination = usePagination<Claim>('/claims', { params: filters })
  const history = useHistory();
  const { path } = useRouteMatch()

  return (
    <>
      <div className="row">
        <div className="col s12">
          <InfiniteScrollTable
            height={ 500 }
            pagination={ pagination }
            onClickItem={ ( data ) => {
              history.push( `${path}/usuario/${data.user.id}`)
            } }
            headers={[
              'Usuário / Id',
              'Data Compra',
              'Gift Card',
              'Código',
              'Fornecedor',
              'Vencimento'
            ]}
            renderData={[
              data => <td>{data?.user?.name} / { data?.user?.id } </td>,
              data => <td>{data?.buyDate} </td>,
              data =>
              (
                <td>
                  R${ data?.card?.giftValue } / { data?.card?.pointsValue ?? 0 }
                </td>
              ),
              data => <td>{data?.code}</td>,
              data => <td>{data?.card?.partner?.name}</td>,
              data => <td>{data?.card?.expireDate ?? '-'}</td>,
            ]}
          />
        </div>
      </div>
    </>
  )

}

interface Props {
  filters?: {
    partner?: string,
    from?: string,
    to?: string,
    region?: string
  }
}

interface Claim{

}


export { ClaimsList }
