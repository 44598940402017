import React from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { GiftCardType } from '../types/GiftCardType'
import { COLORS } from '../utils/constants'

const GiftCard = ( { giftCard, disableLink, ...props }:Props ) => {

  return (
    <Container
      { ...props }
    	backgroud={ giftCard.partner?.cardBackground }
      fontColor={ giftCard.partner?.cardFontColor }
      hoverable={ !disableLink }
    >
      <CardName>{ giftCard.name } </CardName>
      <Points>
        { giftCard.pointsValue } pts
      </Points>
      <Value>
        R$
        <span>
          { giftCard.giftValue }
        </span>
      </Value>
    </Container>
  )
}

type ContainerProps = {
  backgroud?: string
  fontColor?: string
  hoverable?: boolean
}
const Container = styled.div<ContainerProps>`
  position: relative;
  padding: 16px;
  background: ${ props =>
    props.backgroud?.includes( 'http') ?
      `url(${props.backgroud})` :
      props.backgroud ?? 'black'
  };
  width: 260px;
  height: 145px;
  border-radius: 8px;
  color: ${ props => props.fontColor ?? 'black' };
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 50px;
  border: 1px solid #D8D8D8;
  transition: all 300ms;
  cursor: ${ props => props.hoverable ? 'pointer' : '' };

  &:hover{
    box-shadow: ${ props => props.hoverable ? `0 0 20px -5px ${COLORS.APP_GREEN}` : 'unset' } ;
    transition: all 300ms;
  }
`
const CardName = styled.div`
  position: absolute;
  top: calc( 100% + 5px );
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
`

const Points = styled.div`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
`

const Value = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  display: flex;
  span{
    font-size: 30px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    height: 30px;
    margin-left: 5px;
  }

`

interface Props {
  htmlId?: string | number,
  giftCard: GiftCardType,
  disableLink?: boolean
}

export { GiftCard }


